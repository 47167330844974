import React from 'react'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner'
import "./components/Pagina/Pagina.css"

const Loading = () => {
    return (
        <div className="loader">
            <Loader
                type="TailSpin"
                color="#567d26"
                height={75}
                width={75}
                //timeout={2000} //3 secs
            />
        </div>
    )
}

export default Loading;