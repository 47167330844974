import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

//import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import { Helmet } from 'react-helmet';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf/*, faAngleDown*/ } from "@fortawesome/free-solid-svg-icons";

import * as Hosts from '../../../Hosts';
import { reqGET, content_language } from '../../../Utils';

import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Loader from '../../../loader';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

export default function Accordion(props) {

    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState(localStorage.getItem('lang'));

    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);
    const [/*gallery*/, set_gallery] = useState([]);
    //const [documents, set_documents] = useState([]);

    useEffect(() => {

        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })

        try {
            let id = props.info.state.id;
            let slug = props.info.state.slug;
            let preview_id = props.info.state.preview;

            if(id !== '') {
        
                let getPreviewInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${preview_id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
                                    console.log('Normal Preview')
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                let getInfoId = async () => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: 'rascunho ou agendado'}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async () => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: 'rascunho ou agendado'}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    if(res.data[0].content['gallery'] !== undefined && res.data[0].content['gallery'] !== '') {
                                        reqGET(`${Hosts.SIMCore_API}/gallery/${Hosts.companycode}/${res.data[0].content['gallery']}`)
                                            .then(res => {
                                                set_gallery(res.data[0].info.media);
                                            })
                                            .catch(error => console.log(error)/*TODO: GO TO FAIL FETCH*/)
                                    }
        
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId()
                }
                else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId()
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug()
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

            return
        }
        
    }, [props]);

    const get_seo_data = (type) => {

        if(type === 'title') {
            if(content.info.seo !== undefined && content.info.seo['title'] !== undefined  && content.info.seo['title'] !== '') {
                return content.info.seo['title']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE
            }
        }
        else if(type === 'description') {
            if(content.info.seo !== undefined && content.info.seo['description'] !== undefined  && content.info.seo['description'] !== '') {
                return content.info.seo['description']
            } 
            else if(content.content !== undefined && content.content.length > 0 && content.content[0]['abstract'] !== undefined && content.content[0]['abstract'] !== undefined  && content.content[0]['abstract'] !== '') {
                return content.content[0]['abstract']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION
            }
        }
        if(type === 'keywords') {
            if(content.info.seo !== undefined && content.info.seo['keywords'] !== undefined  && content.info.seo['keywords'] !== '') {
                return content.info.seo['keywords']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS
            }
        }
        else if(type === 'image') {
            if(content.content !== undefined && content.content.length > 0 && content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 && content.content[0]['media']['image'][0]['link'] !== '') {
                return content.content[0]['media']['image'][0]['link']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_IMAGE
            }
        }
        else if(type === 'link') {
            let title_seo = Hosts.WEB_SITE_URI + pathname
            return '.' + title_seo.replace("//", "/");
        }
    }

    return (isloading ?

        <Loader/>
        
        :

        <>
        {
        content.info['preview'] === true ? 
            <Preview />
        : 
            null
        }
        <Navbar />
        <Breadcrumb />
        <main className="accordion mt-3">
            <div>
                <Helmet>
                    <title>{ get_seo_data('title') }</title>
                    <link rel="canonical" href={ get_seo_data('link') } />
                    <meta name="description" content={ get_seo_data('description') } />
                    <meta name="keywords" content={ get_seo_data('keywords') } />
                    <meta property="og:url" content={ get_seo_data('link') } />
                    <meta property="og:title" content={ get_seo_data('title') }></meta>
                    <meta property="og:description" content={ get_seo_data('description') }></meta>
                    <meta property="og:image" content={ get_seo_data('image') }></meta>
                    <meta name="twitter:title" content={ get_seo_data('title') }></meta>
                    <meta name="twitter:description" content={ get_seo_data('description') }></meta>
                    <meta name="twitter:image" content={ get_seo_data('image') }></meta>
                </Helmet>
            </div>

            <section className="content-body">
                <div className="container">
                    {
                        content.content.map((el, k) => {  
                            if (k === 0){
                                return (
                                    <div key={k} className="row">

                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?

                                                <div className="div-image w-100"> 
                                                    <a
                                                        href={el['language'][lang_id]['media']['image'][0]['link']}
                                                        data-fancybox="gallery"
                                                        data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                    >
                                                        <AssecImage 
                                                            linkImage={el['language'][lang_id]['media']['image'][0]['link']}
                                                            width="1280"
                                                            height="720"
                                                            fit='crop'
                                                            type='img'
                                                            sClass="img-fluid rounded shadow-sm" 
                                                            alt={el['language'][lang_id]['media']['image'][0]['name']}
                                                        />
                                                        {/*<img src={el['language'][lang_id]['media']['image'][0]['link']} className="img-fluid  rounded shadow-sm" alt={el['language'][lang_id]['media']['image'][0]['name']} />*/}
                                                    </a>

                                                    {
                                                    el['language'][lang_id]['media']['image'].map((el_gal, k_gal) => {
                                                        if(k_gal === 0) {
                                                            return null
                                                        }
                                                        else {
                                                            return (
                                                                <a
                                                                key={k_gal}
                                                                href={el_gal['link']}
                                                                data-fancybox="gallery"
                                                                data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={el_gal['link']}
                                                                    width="450"
                                                                    height="450"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid rounded shadow-sm d-none" 
                                                                    alt={el_gal['name']}
                                                                />
                                                                <img src={el_gal['link']} className="img-fluid rounded shadow-sm d-none" alt={el_gal['name']} />
                                                            </a>
                                                            )
                                                        }
                                                    })
                                                    }

                                                </div>

                                            :

                                                el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ? (
                                                    <div className="div-image w-100">
                                                        <a
                                                            href={el['media']['image'][0]['link']}
                                                            data-fancybox="gallery"
                                                            data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                        >
                                                            <AssecImage 
                                                                linkImage={el['media']['image'][0]['link']}
                                                                width="1280"
                                                                height="720"
                                                                fit='crop'
                                                                type='img'
                                                                sClass="img-fluid rounded shadow-sm" 
                                                                alt={el['media']['image'][0]['name']}
                                                            />
                                                            {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm" alt={el['media']['image'][0]['name']} />*/}
                                                        </a>

                                                        {
                                                        el['media']['image'].map((el_gal, k_gal) => {
                                                            if(k_gal === 0) {
                                                                return null
                                                            }
                                                            else {
                                                                return (
                                                                    <a
                                                                    key={k_gal}
                                                                    href={el_gal['link']}
                                                                    data-fancybox="gallery"
                                                                    data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                >
                                                                    <AssecImage 
                                                                        linkImage={el_gal['link']}
                                                                        width="450"
                                                                        height="450"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid rounded shadow-sm d-none" 
                                                                        alt={el_gal['name']}
                                                                    />
                                                                    {/*<img src={el_gal['link']} className="img-fluid rounded shadow-sm d-none" alt={el_gal['name']} />*/}
                                                                </a>
                                                                )
                                                            }
                                                        })
                                                        }
                                                    </div>

                                                ) : null 

                                        }

                                        {
                                            <div id="div-content" className="div-content col-12 mt-3 mt-md-5 mb-3">
                                                <h2 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                    {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                </h2>
                                                { 
                                                    el.subtitle !== '' ?
                                                        <h2 className={el.subtitle !== '' ? "titulo-secundario mb-3" : "d-none"}>
                                                            {lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                        </h2>
                                                    :
                                                        ''
                                                }
                                                { 
                                                    el.text !== '' ?
                                                        <div className="div-text">
                                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                        </div>
                                                    :
                                                        ''
                                                }
                                            </div>
                                        }

                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 1 ?
                                                
                                                <div className="col-12 mb-5">
                                                    <div className="row div-gallery">
                                                        {
                                                            el.language[lang_id]['media']['image'].slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-2">
                                                                        <AssecImage 
                                                                            linkImage={el_img.link}
                                                                            width="450"
                                                                            height="450"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el_img.name}
                                                                        />
                                                                        <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                
                                            :
                                                el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                                    
                                                    <div className="col-12 mb-5">
                                                        <div className="row div-gallery">
                                                            {
                                                            el.media.image.slice(1).map((el_img, k_img) => {
                                                                return (
                                                                    <div key={k_img} className="col-2">
                                                                        <AssecImage 
                                                                            linkImage={el_img.link}
                                                                            width="450"
                                                                            height="450"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el_img.name}
                                                                        />
                                                                        <img className="img-fluid" src={el_img.link} alt={el_img.name} title={el_img.name} />
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                    
                                                :
                                                    <></>
                                        }
                                        {
                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                
                                                <div className="col-12 div-documents mb-5">
                                                    {
                                                        el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                    
                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                        <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                        {el_doc.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            :
                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                    
                                                    <div className="col-12 div-documents mb-5">
                                                        {
                                                        el.media.doc.map((el_doc, k_doc) => {
                                                            return (
                                                                <div key={k_doc} className="mb-2">
                                                                    {/*<img className="img-fluid" src={el_doc.link} title="" alt="" />
                                                                    <a className="btn btn-primary" href={el_doc.link} rel="noreferrer" target="_blank">{el_doc.name}</a>*/}
                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                        <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                        {el_doc.name}
                                                                    </a>
                                                                </div>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                :
                                                    <></>
                                        }
                                        
                                    </div>
                                )
                            }
                            return ''
                        })
                    }
                    
                </div>
            </section>

            <section className="content-body content-accordion">
                <div className="container">
                    <div className="accordion mb-5" id="accordionContent">
                    {
                        content.content.map((el, k) => { 
                            if (k > 0){
                            
                                return (
                                    <div key={k}>
                                        
                                        <div className="card border-0 mb-2">
                                            <div className="card-header" id={"heading-" + k}>
                                                <h2 className="mb-0">
                                                    <button className="w-100 p-0 bg-transparent border-0 d-flex justify-content-between align-items-center" type="button" data-toggle="collapse" data-target={"#collapse-" + k} aria-expanded="false" /*aria-expanded={k === 1 ? "true" : "false"}*/ aria-controls={"collapse-" + k}>
                                                        <div className='d-flex'>
                                                            <span className='accordion-title'>
                                                                {/*el.title*/}
                                                                {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                            </span>
                                                            { 
                                                                el.subtitle !== '' ?
                                                                    <span className={"subtitulo-secundario"}>
                                                                        &#45;&nbsp;{lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle)}
                                                                    </span>
                                                                :
                                                                    ''
                                                            }
                                                        </div>
                                                        <i className="bi bi-chevron-down" style={{color: 'var(--secondary)'}} />
                                                    </button>
                                                </h2>
                                            </div>

                                            <div id={"collapse-" + k} className={"collapse" /*+ (k === 1 ? " show" : "")*/} aria-labelledby={"heading-" + k} data-parent="#accordionContent">
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className={(((el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0) || (lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0)) ? "col-md-8" : "col-12" )}>
                                                            <div className='card-text'>
                                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                                            </div>
                                                            {
                                                            lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el['language'][lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['doc'] !== undefined && el.language[lang_id]['media']['doc'].length > 0 ?
                                                                <div className="div-documents mb-5">
                                                                    {
                                                                        el.language[lang_id]['media']['doc'].map((el_doc, k_doc) => {
                                                                            return (
                                                                                <div key={k_doc} className="mb-2">
                                                                                    {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                                    
                                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                        <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                        {el_doc.name}
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            :
                                                                el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                                                    <div className="div-documents mb-5">
                                                                        {
                                                                        el.media.doc.map((el_doc, k_doc) => {
                                                                            return (
                                                                                <div key={k_doc} className="mb-2">
                                                                                    {/* <img className="img-fluid" src={el_doc.link} title="" /> */}
                                                                                    
                                                                                    <a href={el_doc.link} rel="noreferrer" target="_blank">
                                                                                        <FontAwesomeIcon className="font-weight-small pr-2" icon={faFilePdf} fixedWidth />
                                                                                        {el_doc.name}
                                                                                    </a>
                                                                                </div>
                                                                            )
                                                                        })
                                                                        }
                                                                    </div>
                                                                :
                                                                    <></>
                                                            }
                                                        </div>
                                                        {
                                                        lang !== 'pt' && el['language'] !== undefined && el['language'][lang_id] !== undefined && el.language[lang_id] !== null && el['language'][lang_id]['media'] !== null && el['language'][lang_id]['media']['image'] !== undefined && el.language[lang_id]['media']['image'].length > 0 ?
                                                            <div className="col-md-4" >
                                                                <a
                                                                    href={el['media']['image'][0]['link']}
                                                                    data-fancybox={"gallery_" + k}
                                                                    data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                                >
                                                                    <AssecImage 
                                                                        linkImage={el['media']['image'][0]['link']}
                                                                        width="450"
                                                                        height="450"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass="img-fluid rounded shadow-sm" 
                                                                        alt={el['media']['image'][0]['name']}
                                                                    />
                                                                    {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm" alt={el['media']['image'][0]['name']} />*/}
                                                                </a>
                                                                
                                                                {
                                                                el['media']['image'].map((el_gal, k_gal) => {
                                                                    if(k_gal === 0) {
                                                                        return null
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <a
                                                                            key={k_gal}
                                                                            href={el_gal['link']}
                                                                            data-fancybox={"gallery_" + k}
                                                                            data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                        >
                                                                            <AssecImage 
                                                                                linkImage={el_gal['link']}
                                                                                width="450"
                                                                                height="450"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid rounded shadow-sm d-none" 
                                                                                alt={el_gal['name']}
                                                                            />
                                                                            {/*<img src={el_gal['link']} className="img-fluid rounded shadow-sm d-none" alt={el_gal['name']} />*/}
                                                                        </a>
                                                                        )
                                                                    }
                                                                })
                                                                }
                                                            </div>
                                                        :
                                                            el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
                                                                <div className="col-md-4">
                                                                    <a
                                                                        href={el['media']['image'][0]['link']}
                                                                        data-fancybox={"gallery_" + k}
                                                                        data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
                                                                    >
                                                                        <AssecImage 
                                                                            linkImage={el['media']['image'][0]['link']}
                                                                            width="450"
                                                                            height="450"
                                                                            fit='crop'
                                                                            type='img'
                                                                            sClass="img-fluid rounded shadow-sm" 
                                                                            alt={el['media']['image'][0]['name']}
                                                                        />
                                                                        {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm" alt={el['media']['image'][0]['name']} />*/}
                                                                    </a>
                                                                
                                                                {

                                                                el['media']['image'].map((el_gal, k_gal) => {
                                                                    if(k_gal === 0) {
                                                                        return null
                                                                    }
                                                                    else {
                                                                        return (
                                                                            <a
                                                                            key={k_gal}
                                                                            href={el_gal['link']}
                                                                            data-fancybox={"gallery_" + k}
                                                                            data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
                                                                        >
                                                                            <AssecImage 
                                                                                linkImage={el_gal['link']}
                                                                                width="450"
                                                                                height="450"
                                                                                fit='crop'
                                                                                type='img'
                                                                                sClass="img-fluid rounded shadow-sm d-none" 
                                                                                alt={el_gal['name']}
                                                                            />
                                                                            {/*<img src={el_gal['link']} className="img-fluid rounded shadow-sm d-none" alt={el_gal['name']} />*/}
                                                                        </a>
                                                                        )
                                                                    }
                                                                })
                                                                }
                                                                </div>
                                                            :
                                                            <></>
                                                        }
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                )
                            }
                            return ''
                        })
                    }
                    </div>
                </div>
            </section>

        </main>
        <Footer />
        </>
    )
}