import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
//import parse from 'html-react-parser';
//import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, set_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
// import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { language } from '../WEBContent/Language';

import AssecImage from "../ASSEC_Image/Image"

export default function PartnerCards(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content_parent, set_content_parent] = useState(data_interface);
    const [list_content, set_list_content] = useState([]);

    useEffect(() => {
        /* console.log('aaaa') */
        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })
            }

        try {
            let getInfoList = async () => {

                await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
                    .then(res => {
                        if(res.data.length > 0) {
                            res.data.forEach((v) => {
                                if (v.info.principal === true) {
                                    //set_dados(dados.current = v);

                                    v.content.childs.forEach((el) => {
                                        
                                        if(el.id_content === '' && ('/' + el.link === pathname)) {
                                            if(el['parent'] !== undefined) {
                                                console.log(el['parent'])
                                            }
                                        }

                                        if(el['children'] !== undefined && el['children'].length > 0) {
                                            el['children'].forEach(async (el_c) => {
                                                if(el_c.id_content === '' && ('/' + el_c.link === pathname)) {
                                                    if(el_c['parent'] !== undefined) {

                                                        await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c['parent']}`)
                                                            .then(res => { 
                                                                if(res.data.length > 0) {
                                            
                                                                    var hoje = new Date();
                                                                    let status = 0
                                                                    if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                                                        var date_p = new Date(res.data[0].info.date.published);
                                            
                                                                        status = 1
                                                                        if(hoje >= date_p) 
                                                                            status = 2
                                                                    }
                                            
                                                                    if(status !== 2) {
                                                                        res.data[0].content = {text: ''}
                                            
                                                                        set_content_parent(res.data[0])   
                                                                    }
                                                                    else {
                                                                        set_content_parent(res.data[0]) 
                                                                    }
                                                                }
                                                            })
                                                            .catch(erro => console.log('ERROR', erro))

                                                    }
                                                }
                                            })
                                        }

                                    })
                                    
                                }
                            })
                        }
                        else {
                            return []
                        }

                    })
                    .catch(erro => console.log('ERROR', erro))

                await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/partner/order`)
                    .then(res => { 
                        if (res.data.length > 0) {

                            var hoje = new Date();
                            let aux = []
                            res.data.forEach((v) => {
                              if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                  var date_p = new Date(v.info.date.published);
                    
                                  if(hoje >= date_p) 
                                    aux.push(v)
                              }
                            })
                            set_list_content(aux)
                            //set_list_content(res.data)
                            //console.log('NOTICIAS', res.data)
                  
                            setTimeout(() => {
                                setLoading(false)
                            }, 1000); 
                          }
                    })
                    .catch(erro => console.log('ERROR', erro))
            }
            getInfoList()
 
            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [pathname]);

    return (isloading ?

		<Loader />
		
		:
        <>
            <Navbar />
            <Breadcrumb />
            <main className="partners-cards">
                <section id="partners-cards" className="content-body">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 py-3 py-md-5">
                                {
                                    content_parent.id !== undefined ?
                                    <div className="row">
                                        <div className="div-content col-12 mb-3 mb-md-5">
                                            <h2 className="titulo-primario">
                                                {/*content_parent['content'][0] !== undefined && content_parent['content'][0]['title'] !== undefined && content_parent.content[0].title !== '' ? content_parent.content[0].title : 'Parceiros'*/}
                                                {(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].title : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].title === undefined || content_parent['content'][0].language[lang_id].title === '' ? content_parent['content'][0].title : content_parent['content'][0].language[lang_id].title))}
                                            </h2>
                                            <h2 className={content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? "titulo-secundario" : "d-none"}>
                                                {/*content_parent['content'][0] !== undefined && content_parent['content'][0]['subtitle'] !== undefined && content_parent.content[0].subtitle !== '' ? content_parent.content[0].subtitle : ''*/}
                                                {(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].subtitle : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].subtitle === undefined || content_parent['content'][0].language[lang_id].subtitle === '' ? content_parent['content'][0].subtitle : content_parent['content'][0].language[lang_id].subtitle))}
                                            </h2>
                                            <div className={"div-text"}>
                                                {/*content_parent['content'][0] !== undefined && content_parent['content'][0]['text'] !== undefined && content_parent.content[0].text !== '' ? ReactHtmlParser(content_parent.content[0].text) : ''*/}
                                                {ReactHtmlParser(lang === 'pt' || content_parent['content'][0].language === undefined ? content_parent['content'][0].text : (content_parent['content'][0].language[lang_id] === undefined || content_parent['content'][0].language[lang_id].text === undefined || content_parent['content'][0].language[lang_id].text === '' ? content_parent['content'][0].text : content_parent['content'][0].language[lang_id].text))}
                                            </div>
                                        </div>
                                    </div>
                                    : null 
                                }
                                    
                                <div className="row p-3">
                                    {
                                    list_content.map((content) => {
                                        return (
                                            content.content.map((el, k) => {  
                                                return (
                                                    <div key={k} className="col-6 col-md-4 col-lg-3 my-3">
                                                        <div className="card partner-item hover-animate">
                                                            <a href={'./partner/' + (content.info['slug'] !== undefined && content.info.slug !== '' ? content.info.slug : content.id)} title={el.title}>
                                                                <div className="company-img"> 
                                                                    <AssecImage 
                                                                        linkImage={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
                                                                        width="400"
                                                                        height="300"
                                                                        fit='crop'
                                                                        type='img'
                                                                        sClass='img-fluid'
                                                                        alt={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['name'] : 'nophoto'}
                                                                    />
                                                                    {/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
                                                                </div>
                                                                <div className="card-body pb-3 d-none d-md-block">
                                                                    <div className="card-title text-center">
                                                                        {(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
                                                                    </div>
                                                                    <div className="card-text">
                                                                        {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                                                    </div>                                                                
                                                                </div>
                                                            </a>
                                                            <div className="card-footer text-center d-none">
                                                                <a className="btn btn-text" href={'./partner/' + content.id} title={language[lang].see_partner}>{language[lang].see_partner}<i className="bi bi-chevron-right"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                )
                                            })
                                        )
                                    })
                                    }
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    )
}