import React, { useState, useEffect } from 'react';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import './Footer.css';

import { language } from '../WEBContent/Language';

import { reqGET, set_language, repo_logo_link, /*repo_site_documents_link,*/ repo_site_assets_link, content_language } from '../../../Utils';

import * as Hosts from '../../../Hosts';

import Loader from '../../../loader';

// import { facebook } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const Footer = () => {

  const [lang, set_lang] = useState(localStorage.getItem('lang'));
  const [/*lang_id*/, set_lang_id] = useState('1');

  const data_interface_navbar = {
    id: '',
    info: {},
    content: {
        childs: []
    }
  }

  const [navbar, set_navbar] = useState(data_interface_navbar);

  const [/*refresh*/, setRefresh] = useState(0);

  const [isloading, setLoading] = useState(true);

  const location = useLocation().pathname;

  // const change_lang = (l) => {
  //   set_language(l)
  //   set_lang(l)

  //   //console.log('language', localStorage.getItem('lang'))
  //   //console.log('localstorage', localStorage)
  // }

  useEffect(() => {

    if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
      set_lang('pt')
      set_language('pt')
      set_lang_id('1')
    }
    else {
        set_lang(localStorage.getItem('lang'))
        content_language.forEach(el => {
            if (el.code === localStorage.getItem('lang')) {
            set_lang_id(el.id)
            }
        })
    }

    let getInfoNavbar = async () => {

      await reqGET(`${Hosts.SIMCore_API}/web/structure/${Hosts.companycode}/1`)
        .then(res => {
            if(res.data.length > 0) {
                res.data.forEach((v) => {
                    if (v.info.principal === true) {
                        //set_dados(dados.current = v);

                        if(lang !== 'pt') {
                            let x = 0
                            content_language.forEach((cl) => {
                                if(cl.code === lang) {
                                    v.content.childs.forEach(async (el) => {
                                        if(el.id_content !== '') {
                                            if(el.children !== undefined) {
                                                x = x - el.children.length
                                                el.children.forEach(async (el_c) => {
                                                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el_c.id_content}`)
                                                        .then(res_3 => {
                                                            if(res_3.data.length > 0) {
                                                                if(res_3.data[0].info.language.length > 0) {
                                                                    if(res_3.data[0].info.language[cl.id] !== undefined && res_3.data[0].info.language[cl.id] !== null) {
                                                                        if(res_3.data[0].info.language[cl.id]['name'] !== '') {
                                                                            el_c.title = res_3.data[0].info.language[cl.id]['name'];
            
                                                                            x++
                                                                            if(v.content.childs.length === x) {
                                                                                setRefresh(refresh => refresh + 1)
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                else {
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                        setRefresh(refresh => refresh + 1)
                                                                    }
                                                                } 
                                                            }
                                                            else {
                                                                x++
                                                                if(v.content.childs.length === x) {
                                                                    setRefresh(refresh => refresh + 1)
                                                                }
                                                        } 
                                                    })
                                                })
                                            }

                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                .then(res_2 => {
                                                    if(res_2.data.length > 0) {
                                                        if(res_2.data[0].info.language.length > 0) {
                                                            if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                    el.title = res_2.data[0].info.language[cl.id]['name'];
                                                                    
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                      setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if(v.content.childs.length === x) {
                                                              setRefresh(refresh => refresh + 1)
                                                            }
                                                        }  
                                                    } 
                                                    else {
                                                        x++
                                                        if(v.content.childs.length === x) {
                                                          setRefresh(refresh => refresh + 1)
                                                        }
                                                    } 
                                                })

                                            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${el.id_content}`)
                                                .then(res_2 => {
                                                    if(res_2.data.length > 0) {
                                                        if(res_2.data[0].info.language.length > 0) {
                                                            if(res_2.data[0].info.language[cl.id] !== undefined && res_2.data[0].info.language[cl.id] !== null) {
                                                                if(res_2.data[0].info.language[cl.id]['name'] !== '') {
                                                                    el.title = res_2.data[0].info.language[cl.id]['name'];
      
                                                                    x++
                                                                    if(v.content.childs.length === x) {
                                                                      setRefresh(refresh => refresh + 1)
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            x++
                                                            if(v.content.childs.length === x) {
                                                              setRefresh(refresh => refresh + 1)
                                                            }
                                                        } 
                                                    }
                                                    else {
                                                        x++
                                                        if(v.content.childs.length === x) {
                                                          setRefresh(refresh => refresh + 1)
                                                        }
                                                    } 
                                                })

                                        }
                                        else {
                                            if(el['language'] !== undefined && el['language'] !== null) {
                                                if(el.language[cl.id] !== undefined && el.language[cl.id]['title'] !== undefined && el.language[cl.id]['title'] !== '') { 
                                                    el.title = el.language[cl.id]['title']
      
                                                    x++
                                                    if(v.content.childs.length === x) {
                                                      setRefresh(refresh => refresh + 1)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                }
                            })
                        }

                        set_navbar(v);
                        setLoading(false)
                    }
                })
            }
            else {
                return []
            }

        })
        .catch(erro => console.log('ERROR', erro))
    }

    //set_navbar(navbar = { id: '', info: '', content: { childs: [] } })

    getInfoNavbar()

    // if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
    //   change_lang('pt')
    // }
    // else {
    //   set_lang(localStorage.getItem('lang'))
    // }


  }, [lang])


const page_location = useLocation().pathname.split('/').pop();

  return (

    isloading ? 
      <Loader />
    :
      <>
        
        <footer className="footer">
          <div className="container">
            <div className="row pt-5 pb-2">
              {/*<div className="col-12 mb-2 d-none">
                <a href={Hosts.WEB_SITE_URI} title={Hosts.webSiteTitle} alt={Hosts.webSiteTitle} >
                  <img src={repo_logo_link("logo-white.svg")} width="180px" height="75px" alt={Hosts.webSiteTitle} className="img-fluid" />
                </a>
              </div>/*/}

              <div className="col-12 col-md-5">
                <h5 className=" my-2">{language[lang].contacts}</h5>
                <span className="address d-flex">
                  <i className="bi bi-geo-alt pr-2" />
                  <h6>{parse(language[lang].footer_address_1)}</h6>
                </span>
                <span className="address d-flex">
                  {/* <img alt={language[lang].phone} className="pr-2" height="20px" src={repo_site_assets_link("telefone.svg")} /> */}
                  <i className="bi bi-telephone pr-2" />
                  <a href={'callto:' + (language[lang].footer_address_2)}>
                    <h6>{parse(language[lang].footer_address_2)}{parse(language[lang].call_landline)}</h6>
                  </a>
                </span>
                <span className="address d-flex">
                  {/* <img alt={language[lang].email} className="pr-2" height="20px" src={repo_site_assets_link("email.svg")} /> */}
                  <i className="bi bi-envelope pr-2" />
                  <a href={'mailto:' + language[lang].footer_address_3}><h6>{language[lang].footer_address_3}</h6></a>
                </span>
              </div>

              <div className="col-6 col-md-2">
                <h5 className="my-2">{language[lang].site_map}</h5>
                {
                  navbar.content.childs.map((v, key) => {

                    //console.log(v)
                    let link = ''
                    let current_top_check = ''
                    v.id_content !== '' ?
                      link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : Hosts.WEB_SITE_URI + 'pag/' + v.id_content)
                    :
                      link = (v.slug !== undefined && v.slug !== '' ? Hosts.WEB_SITE_URI + '' + v.slug : (v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www'  ? Hosts.WEB_SITE_URI + v.link : (v.link.substr(0, 3) === 'www' ? 'http://' + v.link : v.link)))
                    
                    current_top_check = (v.slug !== undefined && v.slug !== '' ? v.slug : (v.id_content !== '' ? v.id_content : v.link));

                    if(v.children !== undefined && v.children !== '' && v.children.length > 0){

                        v.children[0].id_content !== '' ?
                          link = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? Hosts.WEB_SITE_URI + '' + v.children[0].slug : Hosts.WEB_SITE_URI + 'pag/' + v.children[0].id_content)
                        :
                          link = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? Hosts.WEB_SITE_URI + '' + v.children[0].slug : (v.children[0].link.substr(0, 4) !== 'http' && v.children[0].link.substr(0, 3) !== 'www'  ? Hosts.WEB_SITE_URI + v.children[0].link : (v.children[0].link.substr(0, 3) === 'www' ? 'http://' + v.children[0].link : v.children[0].link)))
                          
                        current_top_check = (v.children[0].slug !== undefined && v.children[0].slug !== '' ? v.children[0].slug : v.children[0].id_content);

                        if(current_top_check === '') {
                          if(location === '/' + v.children[0].link) {
                            current_top_check = v.children[0].link
                          }
                        }
                    }

                    return (
                        v.link.substr(0, 4) !== 'http' && v.link.substr(0, 3) !== 'www' ?
                          <a key={key} href={link} className={((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_bottom' : '')}>
                              <h6 className="d-flex">
                                {v.title}
                              </h6>
                          </a>
                        :
                          <a key={key} href={link} className={((page_location !== '' && page_location === current_top_check) || (page_location !== '' && location === '/' + current_top_check) ? 'current_bottom' : '')} target="_blank" rel="noreferrer">
                            <h6 className="d-flex">
                                {v.title}
                            </h6>
                          </a>
                    )
                  })
                }

              </div>

              <div className="col-6 col-md-3">
                <h5 className=" my-2">{language[lang].legal_info}</h5>
                <a className={(page_location === 'terms-conditions' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "terms-conditions"}>
                  <h6 className="mb-2">{language[lang].terms_conditions}</h6>
                </a>
                <a href="https://www.livroreclamacoes.pt/" alt={language[lang].complaint_book} title={language[lang].complaint_book} target="_blank" rel="noreferrer">
                  <h6 className="mb-2">{language[lang].complaint_book}</h6>
                </a>
              </div>

              <div className="col-6 col-md-2">
                <h5 className=" my-2">{language[lang].footer_social}</h5>
                <div className='social-media'>
                  <a href={Hosts.fbLink} title={Hosts.fbLink} alt={Hosts.fbLink} target="_blank" rel="noreferrer" >
                    <i className="bi bi-facebook img-fluid p-2" style={{ fontSize: "x-large" }} />
                  </a>
                  <a href={Hosts.instaLink} title={Hosts.instaLink} alt={Hosts.instaLink} target="_blank" rel="noreferrer" >
                    <i className="bi bi-instagram img-fluid p-2" style={{ fontSize: "x-large" }} />
                  </a>
                  {/* <a href={Hosts.linkedinLink} title={Hosts.linkedinLink} alt={Hosts.linkedinLink} target="_blank" rel="noreferrer" >
                    <i className="bi bi-linkedin img-fluid p-2" style={{ fontSize: "x-large" }} />
                  </a> */}
                  <a href={Hosts.youtubeLink} title={Hosts.youtubeLink} alt={Hosts.youtubeLink} target="_blank" rel="noreferrer" >
                    <i className="bi bi-youtube img-fluid p-2" style={{ fontSize: "x-large" }} />
                  </a>
                </div>
              </div>

              <div className="col-12">
                <div className="row justify-content-center" style={{ columnGap: 15}}>
                    <div className="">
                      <h6 className="mt-3 mb-1">{language[lang].footer_logos_01}</h6>
                      <a href={Hosts.site_AMCB} title={Hosts.site_AMCB} alt={Hosts.site_AMCB} target="_blank" rel="noreferrer" >
                        <img src={repo_site_assets_link("AMCB.svg")} width="" height="" alt={Hosts.site_AMCB} className="img-fluid" />
                      </a>
                    </div>
                    <div className="order-3 order-md-2">
                      <h6 className="mt-3 mb-1">{language[lang].footer_logos_02}</h6>
                      <div className="logosfooter">
                        <a href={Hosts.site_RNAE} title={Hosts.site_RNAE} alt={Hosts.site_RNAE} target="_blank" rel="noreferrer" >
                          <img src={repo_site_assets_link("RNAE.svg")} width="" height="" alt={Hosts.site_RNAE} className="img-fluid" />
                        </a>
                        <a href={Hosts.site_UBI} title={Hosts.site_UBI} alt={Hosts.site_UBI} target="_blank" rel="noreferrer" >
                          <img src={repo_site_assets_link("UBI.svg")} width="" height="" alt={Hosts.site_UBI} className="img-fluid" />
                        </a>
                        <a href={Hosts.site_IPG} title={Hosts.site_IPG} alt={Hosts.site_IPG} target="_blank" rel="noreferrer" >
                          <img src={repo_site_assets_link("IPG.svg")} width="" height="" alt={Hosts.site_IPG} className="img-fluid" />
                        </a>
                        <a href={Hosts.site_IPCB} title={Hosts.site_IPCB} alt={Hosts.site_IPCB} target="_blank" rel="noreferrer" >
                          <img src={repo_site_assets_link("IPCB.svg")} width="" height="" alt={Hosts.site_IPCB} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                    <div className="order-2 order-md-3">
                      <h6 className="mt-3 mb-1">{language[lang].footer_logos_03}</h6>
                      <div className="logosfooter">
                        <a href={Hosts.site_ERSE} title={Hosts.site_ERSE} alt={Hosts.site_ERSE} target="_blank" rel="noreferrer" >
                          <img src={repo_site_assets_link("ERSE.svg")} width="" height="" alt={Hosts.site_ERSE} className="img-fluid" />
                        </a>
                      </div>
                    </div>
                </div>
                
                <div className="row justify-content-center">
                  <div className="col-12 copyright text-center">
                    <span className="mt-2">{language[lang].footer_logos_text}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 text-center py-3 justify-content-center border-top">
            <ul className="list-inline mb-0 copyright">
              <li className="list-inline-item p-0 mr-0">
                <span>
                  <i className="far fa-copyright"></i> 2023 {Hosts.webSiteTitle}
                </span>
              </li>
              <li className="list-inline-item p-0 mr-0">
                <span className="px-2">&#124;</span>
                <span>
                  <a className={(page_location === 'terms-conditions' ? 'current_bottom' : '')} href={Hosts.WEB_SITE_URI + "terms-conditions"}>
                    {language[lang].terms_conditions}
                  </a>
                </span>
                <span className="px-2">&#124;</span>
              </li>
              <li className="list-inline-item p-0">
                <span>{language[lang].developed}</span>
              </li>
              <li className="list-inline-item p-0">
                <span>
                  <img className="img-fluid" src={repo_logo_link("logo_genuinetask.svg")} alt="Genuinetask" title="Genuinetask" />
                  {/*<a href="http://sim.assec.pt" title="Assec Sim!" target="_blank" rel="noreferrer">
                    Genuinetask
              </a>*/}
                </span>
              </li>
            </ul>
          </div>
        </footer>

        {/*<footer className="bg-color-body text-white">
          <div className="container d-print-none">
            <div className="row">
              <div className='col-12 text-center'>
                <a href={repo_site_documents_link('CENTRO-04-38D7-FEDER-001180.pdf')} target="_blank" rel="noreferrer" alt="Centro 2020" title="Centro 2020" >
                  <img src={repo_site_assets_link("centro_2020_FEDER-branco.svg")} alt="Centro 2020" title="Centro 2020" className="img-fluid" />
                </a>
              </div>
            </div>
            <div className="row py-5 py-sm-5 flex-column flex-md-row justify-content-lg-between">
              <div className="col-12 col-md-3">
                <img src={repo_logo_link("logo-branco.svg")} width="120px" height="75px" alt={Hosts.webSiteTitle} className="img-fluid d-none pb-2" />
                <span className="address">
                  <h5 className="font-weight-bold">{parse(language[lang].footer_address_1)}</h5>
                </span>
                <span className="address mb-md-3">
                  <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("localizacao.svg")} />
                  {parse(language[lang].footer_address_11)}
                </span>
                <span className="address mb-md-1">
                  <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("telefone.svg")} />
                  <a href={'tel:' + language[lang].footer_address_12}>
                    {language[lang].footer_address_12}
                    <br></br>
                    <small>{language[lang].call_landline}</small>
                  </a>
                </span>
                <span className="address mb-md-3">
                  <img alt='' className="pr-2" height="20px" src={repo_site_assets_link("email.svg")} />
                  <a href={'mailto:' + language[lang].footer_address_13}>{language[lang].footer_address_13}</a>
                </span>
                <span className="address">
                  {language[lang].footer_address_14}
                </span>
              </div>
              <div className="col-12 col-md-4 mt-3 mt-md-0">
              {
                navbar.content.childs.map((v, key) => {

                  //console.log('Menu Parents: ', v)

                  if(v.id === 11 && v.children !== undefined && v.children !== '' && v.children.length > 0) { 

                    return (

                      <div key={key}>
                        <h5 className="font-weight-bold">{v.title}</h5>
                        {

                          v.children.map((v2, key2) => {

                            //console.log('Childrens: ', v2)

                            let link_content_dropdown_item = ''
                            
                            v2.id_content !== '' ?
                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content)
                            :
                                link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : (v2.link.substr(0, 4) !== 'http' && v2.link.substr(0, 3) !== 'www'  ? Hosts.WEB_SITE_URI + v2.link : (v2.link.substr(0, 3) === 'www' ? 'http://' + v2.link : v2.link)))


                            //let link_content_dropdown_item = (v2.slug !== undefined && v2.slug !== '' ? Hosts.WEB_SITE_URI + '' + v2.slug : Hosts.WEB_SITE_URI + 'pag/' + v2.id_content);
                            let current_top_dropdown = (v2.slug !== undefined && v2.slug !== '' ? v2.slug : (v2.id_content !== '' ? v2.id_content : v2.link));

                            if(current_top_dropdown === '') {
                              if(location === '/' + v2.link) {
                                  current_top_dropdown = v2.link
                              }
                            }

                            return (

                              <div key={key2}>

                                <a href={link_content_dropdown_item} className={ ((page_location !== '' && page_location === current_top_dropdown) || (page_location !== '' && location === '/' + current_top_dropdown) ? 'current_bottom' : '')}>
                                    {v2.title}
                                </a>

                              </div>

                            )
                          })
                        }
                      </div>
                    )


                  }
                  else 
                    return null
                })
              }
              </div>
              <div className="d-none">
                <h5 className="font-weight-bold">Quick Links</h5>
                <div className="d-flex flex-column justify-content-start">
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./turistrela">A Turistrela</a></span>
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./destino-serra-da-estrela">Destino Serra da Estrela</a></span>
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./estrategia-de-desenvolvimento-sustentavel">Estratégia de Desenvolvimento Sustentável</a></span>
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./estrelanofuturo">Estrela no Futuro</a></span>
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./partner/all">Parceiros de Negócios</a></span>
                </div>
              </div>
              <div className="col-12 col-md-3 mt-3 mt-md-0">
                <h5 className="font-weight-bold">{language[lang].legal_info}</h5>
                <div className="d-flex flex-column justify-content-start">
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="./termos-e-condicoes">{language[lang].terms_conditions}</a></span>
                    <span><i className="fa fa-angle-right mr-2 d-none"></i><a href="https://www.livroreclamacoes.pt/" target="_blank" rel="noreferrer">{language[lang].complaint_book}</a></span>
                </div>
              </div>
              <div className="col-12 col-md-2 mt-3 mt-md-0">
                <h5 className="font-weight-bold mb-4 d-none d-lg-block">{language[lang].footer_social}</h5>
                <span className="logosfooter redes my-3 my-md-0 align-items-center">
                  <a href={Hosts.fbLink} title={Hosts.fbLink} alt={Hosts.fbLink} target="_blank" rel="noreferrer" >
                    <img src={repo_site_assets_link("facebook.svg")} width="" height="" alt={Hosts.fbLink} className="img-fluid" />
                  </a>
                  <a href={Hosts.instaLink} title={Hosts.instaLink} alt={Hosts.instaLink} target="_blank" rel="noreferrer" >
                    <img src={repo_site_assets_link("instagram.svg")} width="" height="" alt={Hosts.instaLink} className="img-fluid" />
                  </a>
                </span>
              </div>
            </div>
          </div>
          <div className="container-fluid bg-color-dark text-white">
            <div className="row">
              <div className="container">
                <div className="row">
                  <div className="col-12 footer-bar py-4">
                    <ul className="list-inline flex-column flex-sm-row text-center mb-0 copyright">
                      <li>
                        <span><i className="far fa-copyright pr-1"></i> 2022 <span style={{fontWeight: 600}} className="pl-1">{Hosts.webSiteTitle}</span>.</span>
                        <span>{language[lang].reserved_rights}</span>
                      </li>
                      <li>
                        <span>
                          {language[lang].developed} 
                          <a href="http://sim.assec.pt" title="Assec Sim!" alt="Assec Sim!" target="_blank" rel="noreferrer" className="pl-1">
                          Assec Sim!
                          </a>
                        </span>
                      </li>
                    </ul>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>*/}
      </>

  )

};
export default Footer;