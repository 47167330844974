/** Ficheiro de Configuração de Acessos Remotos */


if (process.env.REACT_APP_MODE === 'production') console.log = function () { };

// ============== PARA ELIMINAR ============== 

export const SITES = {
    SIMCONFERENCE: {
        ACCESS_TOKEN: "SIMCONFERENCE;I1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
    },
    SIMWEBSITE: {
        ACCESS_TOKEN: "SIMWEBSITE;1ZXI1NiJ9.eyJSb2xlIjoiQWRtaW4iLCJJc3N1ZXIiOiJJc3N1ZXIiLCJVc2VybmFtZSI6IkphdmFJblVzZSIsImV4cCI6MTYzNTQxNzIxMCwiaWF0IjoxNjM1NDE3MjEwfQ.lwwQmI9SGdUZm3tJm2YOmIIuwx82CBcrO_WZmJn3CGQ",
        DEFAULT_SEO_TITLE: "AgroEfficiency :: AMCB",
        DEFAULT_SEO_DESCRIPTION: "A Associação de Municípios da Cova da Beira (AMCB) propõe-se a realizar uma medida de carácter intangível, direcionada a empresas do setor agroalimentar, agricultores que tem negócios em nome individual e cooperativas agrícolas localizadas em todo o território nacional, com o objetivo de disponibilizar informação relevante, criar ferramentas e sensibilizar o público-alvo para a adoção de hábitos de consumo de energia (elétrica e de gás natural) eficientes e sustentáveis. <br />Com a implementação do projeto, prevê-se a análise e operacionalização das melhores práticas de mercado (inclui a realização de auditorias energéticas), desenvolvimento de plataforma de partilha pública sobre a temática da eficiência energética (inclui ferramenta de gestão de consumos energéticos, formação, vídeos técnicos de poupança de energia e ferramenta de CRM) e desenvolvimento de rótulo AgroEfficiency, incentivando as entidades a utilizar medidas de sustentabilidade energética na produção. <br />Tendo em consideração as especificidades do público serão utilizados meios tradicionais de promoção, por um lado, mas ir-se-á estimular a utilização das tecnologias como ferramentas de gestão e de acesso ao conhecimento por outro.",
        DEFAULT_SEO_KEYWORDS: "AMCB, AgroEfficiency, Agro, Eficiência, eletricidade, gás, racionalização, consumo, elearning,monitorização, cova da beira, agroalimentar, auditoria, manuais, boas práticas",
        DEFAULT_SEO_IMAGE: 'https://cdn.assec.app/repo/amcb/website/static/assets/img/facebook_share_default_image.jpg',
    }
}





//const webSiteURL = '/demo/assec/sim/conference/'

//const emailWebSiteURL = `https://sede.assec.pt${webSiteURL}`

//const backofficeURL = '/demo/assec/sim/conference-gest'

//const emailBackofficeURL = `https://sede.assec.pt${backofficeURL}`

// ============================================ 


export const webSiteTitle = 'AgroEfficiency'

export const webSiteAdress = ''

export const companycode = '0000000';
//const companycode = '0000251';

//export const contactFrom = 'web.dev@assec.pt';
export const contactFrom = process.env.REACT_APP_API_EMAIL_CONTACT

export const site_AMCB = 'https://www.amcb.pt/';
export const site_RNAE = 'http://www.rnae.pt/';
export const site_UBI = 'https://www.ubi.pt/';
export const site_IPG = 'https://politecnicoguarda.pt/';
export const site_IPCB = 'https://ipcb.pt/';
export const site_ERSE = 'https://www.erse.pt/inicio/';


export const fbLink = 'https://www.facebook.com/agroefficiency.amcb';
export const instaLink = 'https://www.instagram.com/agroefficiency_amcb/';
export const linkedinLink = '';
export const youtubeLink = 'https://www.youtube.com/@AgroEfficiency-amcb';


// ########################### CONFIG ##################################

export const EMAIL_BODY_BACKGROUND_COLOR = ' #F7F7F7'
export const EMAIL_TEXT_SIZE = ' 14px'
export const EMAIL_TEXT_LINE_HEIGHT = ' 24px'
export const EMAIL_TEXT_COLOR = ' #444444'
export const EMAIL_BACKGROUND_COLOR = ' #FFFFFF'
export const FOOTER_EMAIL_TEXT_SIZE = ' 12px'
export const FOOTER_EMAIL_TEX_LINE_HEIGHT = ' 20px'
export const FOOTER_EMAIL_COLOR = 'transparent'
export const FOOTER_EMAIL_TEXT_COLOR = '#656565'

console.log('HOSTS APPLICATION_URL:', process.env.REACT_APP_PRINCIPAL_URL)

console.log('HOSTS APPLICATION_SITE_URI:', process.env.REACT_APP_PRINCIPAL_URL)
export const WEB_SITE_URI = process.env.REACT_APP_SITE_URI

console.log('HOSTS APPLICATION_BACKOFFICE_URI:', process.env.REACT_APP_GEST_URI)
export const WEB_BACKOFFICE_URI = process.env.REACT_APP_GEST_URI

console.log('NODE_ENV SIMCore_API:', process.env.REACT_APP_API_URL)
export const SIMCore_API = process.env.REACT_APP_API_URL;

console.log('NODE_ENV APP_REPO_FOLDER:', process.env.REACT_APP_REPO_FOLDER)
export const APP_REPO_FOLDER = process.env.REACT_APP_REPO_FOLDER

console.log('NODE_ENV REPO URL:', process.env.REACT_APP_REPO_URL)
export const REPO = process.env.REACT_APP_REPO_URL

console.log('NODE_ENV SIMCore_API_MAIL:', process.env.REACT_APP_API_EMAIL_URL)
export const SIMCore_API_MAIL = process.env.REACT_APP_API_EMAIL_URL

console.log('NODE_ENV EMAIL_WEB_SITE_URL:', process.env.REACT_APP_SITE_URL)
console.log('NODE_ENV EMAIL_WEB_SITE_URL_REAL :', process.env.REACT_APP_SITE_URL_REAL)
export const EMAIL_WEB_SITE_URL = process.env.REACT_APP_SITE_URL_REAL

console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL:', process.env.REACT_APP_GEST_URL)
console.log('NODE_ENV EMAIL_WEB_BACKOFFICE_URL_REAL:', process.env.REACT_APP_BACKOFFICE_URL_REAL)
export const EMAIL_WEB_BACKOFFICE_URL = process.env.REACT_APP_BACKOFFICE_URL_REAL


// #####################################################################


