import React, { useState, useEffect } from 'react';

/* import { Link } from 'react-router-dom'; */ 

import Slider from "./Slider/Slider";
import Footer from "./Footer/Footer";

import parse from 'html-react-parser';
import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';

import Navbar from "./Navbar/Navbar";

import "./Pagina.css";

import * as Hosts from '../../Hosts';
import {
  set_language,
  reqGET,
  repo_img_link,
  content_language

} from '../../Utils';

import AssecImage from "./ASSEC_Image/Image"

// get our fontawesome imports
import { language } from './WEBContent/Language';

import { Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectFade} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';


import Modal from "./Forms/OpinionModal";

const Pagina = props => {
	/* variável do tipo array */
	const [/*isloading*/, setLoading] = useState(true);
	const [lang, set_lang] = useState('pt');
	const [lang_id, set_lang_id] = useState('1');
	const [dataProject, setDataProject] = useState([]);
	//const [dataInfo, setDataInfo] = useState([]);
	const [, setDataInfo] = useState([]);
	const [dataDestaque, setDataDestaque] = useState([]);
	//const [dataPlatform, setDataPlatform] = useState([]);
	const [, setDataPlatform] = useState([]);
	const [list_content, set_list_content] = useState([]);
	const [list_insights, set_list_insights] = useState([]);
	const [list_partners, set_list_partners] = useState([]);
	const [list_companies, set_list_companies] = useState([]);
	const [dataTestimony, setDataTestimony] = useState([]);
	const [dataBusiness, setDataBusiness] = useState([]);
	// const [, setBanner] = useState([]);
	const [show, setShow] = useState(false);
	/*let getInfo = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/banner`)
			.then(res => {
				if (res.data.length > 0) {
				var hoje = new Date();
				let aux = []
				res.data.forEach((v) => {
					if(v.info.date.published !== undefined && v.info.date.published !== '') {
						var date_p = new Date(v.info.date.published);

						if(hoje >= date_p) 
						aux.push(v)
					}
				})
				setBanner(aux)
				//set_list_content(res.data)
				//console.log('NOTICIAS', res.data)

				setLoading(false)
				}
				//setBanner(res.data)
				//console.log(res.data)
				//setLoading(false)
				//calculo_margem()
			})
			.catch(erro => console.log('Erro', erro))
	}*/
	let getInfoDestaque = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/91`)
		.then(res => {
			if(res.data !== undefined && res.data !== null && res.data.length > 0) {
				setDataDestaque(res.data)
			}
			//console.log(res.data)
			setLoading(false)
			//calculo_margem()
		})
		.catch(erro => console.log('Erro', erro))
	}
	let getInfoProject = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/48`)
		.then(res => {
			if(res.data !== undefined && res.data !== null && res.data.length > 0) {
				setDataProject(res.data)
			}
			//console.log('RES DATA', res.data[0])

		})
		.catch(erro => console.log('Erro', erro))
	}
	let getInfoEntrepreneur = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/49`)
		.then(res => {
			if(res.data !== undefined && res.data !== null && res.data.length > 0) {
				setDataInfo(res.data)
			}
			//console.log('RES DATA', res.data[0])

		})
		.catch(erro => console.log('Erro', erro))
	}
	let getInfoPlatforms = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/52`)
		.then(res => {
			if(res.data !== undefined && res.data !== null && res.data.length > 0) {
				setDataPlatform(res.data)
			}
			//console.log('PLATAFORMAS', res.data)

		})
		.catch(erro => console.log('Erro', erro))
	}
	let getInfoList = async () => {
		await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/news`)
		.then(res => {
			if (res.data.length > 0) {

			var hoje = new Date();
			let aux = []
			res.data.forEach((v) => {
				if(v.info.date.published !== undefined && v.info.date.published !== '') {
				var date_p = new Date(v.info.date.published);

				if(hoje >= date_p){
					/* aux.push(v) */
					if(v.info.date.avaiable !== undefined && v.info.date.avaiable !== '') { 
					var date_a = new Date(v.info.date.avaiable);
					if(hoje <= date_a) {
						aux.push(v)
					}
					} else{
						aux.push(v)
					}
				}
				}
			})
			set_list_content(aux)
			//set_list_content(res.data)
			//console.log('NOTICIAS', res.data)

			setLoading(false)
			}
		})
		.catch(erro => console.log('ERROR', erro))
		}
	let getInsightsList = async () => {
		await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/insight`)
		.then(res => {
			if (res.data.length > 0) {

			var hoje = new Date();
			let aux = []
			res.data.forEach((v) => {
				if(v.info.date.published !== undefined && v.info.date.published !== '') {
				var date_p = new Date(v.info.date.published);

				if(hoje >= date_p){
					/* aux.push(v) */
					if(v.info.date.avaiable !== undefined && v.info.date.avaiable !== '') { 
					var date_a = new Date(v.info.date.avaiable);
					if(hoje <= date_a) {
						aux.push(v)
					}
					} else{
						aux.push(v)
					}
				}
				}
			})
			set_list_insights(aux)
			//set_list_content(res.data)
			//console.log('NOTICIAS', res.data)

			setLoading(false)
			}
		})
		.catch(erro => console.log('ERROR', erro))
	}
	let getTestimony = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/testimony/creation`)
		.then(res => {
			if(res.data !== undefined && res.data !== null && res.data.length > 0) {
				setDataTestimony(res.data.filter(v => v.info.status === 'accepted'))
			}
			//console.log('PLATAFORMAS', res.data)

		})
		.catch(erro => console.log('Erro', erro))
	}
	let getBusiness = async () => {
		//setLoading(true)
		reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/99`)
		.then(res => {
			if(res.data !== undefined && res.data !== null && res.data.length > 0) {
				setDataBusiness(res.data)
			}
		})
		.catch(erro => console.log('Erro', erro))
	}
	let getPartnersList = async () => {
		await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/partner/order`)
			.then(res => { 
				if (res.data.length > 0) {

					var hoje = new Date();
					let aux = []
					res.data.forEach((v) => {
						if(v.info.date.published !== undefined && v.info.date.published !== '') {
							var date_p = new Date(v.info.date.published);
			
							if(hoje >= date_p) 
							aux.push(v)
						}
					})
					set_list_partners(aux)
			
					setLoading(false)
					}
			})
			.catch(erro => console.log('ERROR', erro))
	}
	let getCompaniesList = async () => {
		await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/company/order`)
			.then(res => { 
				if (res.data.length > 0) {

					var hoje = new Date();
					let aux = []
					res.data.forEach((v) => {
						if(v.info.date.published !== undefined && v.info.date.published !== '') {
							var date_p = new Date(v.info.date.published);
			
							if(hoje >= date_p) 
							aux.push(v)
						}
					})
					set_list_companies(aux)
			
					setLoading(false)
					}
			})
			.catch(erro => console.log('ERROR', erro))
	}

	useEffect(() => {
		//getInfo()
		getInfoDestaque()
		getInfoProject()
		getInfoEntrepreneur()
		getInfoPlatforms()
		getInfoList()
		getTestimony()
		getBusiness()
		getInsightsList()
		getPartnersList()
		getCompaniesList()
		
		if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
			set_lang('pt')
			set_language('pt')
			set_lang_id('1')
		}
		else {
			set_lang(localStorage.getItem('lang'))
			content_language.forEach(el => {
				if (el.code === localStorage.getItem('lang')) {
					set_lang_id(el.id)
				}
			})
		}
	}, [])
	return (

	<div>
		<Navbar />
		<main>
			<Slider />

			<section id="project" className="py-3 py-lg-5">
				<div className="container">
           			<div className="row">
              			<div className="col-12 offset-lg-1 col-lg-10 pt-5 pb-3 py-md-5">

							{
							dataProject.map((v, key) => {
								return (
									//console.log('PROJECT', v.content[0].text)
									<div key={key}>
									<div className="mb-4">
											<h3 className="titulo-pp text-center">
												{/*{language[lang].project}*/}
												{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
											</h3>
											<p className="subtitulo-secundario">
												{/*{language[lang].project_banner_text}*/}
												{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
											</p>
										</div>
										<div className="mb-4">
											{/*{parse(v.content[0].text)}*/}
											{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
										</div>
										<div className="col-12 mt-3 mt-md-5 text-center">
											<a href={'./o-projeto'} className="btn btn-primary shadow-sm">
												{language[lang].btn_mote}
											</a>
										</div>
									</div>
								)
							})
							}

              			</div>
            		</div>
            	</div>
        	</section>

			<section id="destaque" className="parallax py-5" style={{ backgroundImage: `url(` + repo_img_link("banner-destaque.jpg") + `)` }}>
				<div className="container-fluid overlay-img mp-mask">
					<div className="row">
			
						{/* <img alt='' className="img-fluid" src={repo_img_link("banner-snow.png")} /> */}
						<div className="container">
							<div className="row">
								<div className="col-12 col-md-8 col-lg-7 col-xl-5 text-white">
									{
									dataDestaque.map((v, key) => {
										//console.log('------------> V', v)
										return (
											<div key={key}>
												<div className="mb-4">
													<h3 className="titulo-pp text-white">
														{/*{language[lang].project}*/}
														{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
													</h3>
													<p className="subtitulo-secundario">
														{/*{language[lang].project_banner_text}*/}
														{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
													</p>
												</div>
												<div className="mb-4">
													{/*{parse(v.content[0].text)}*/}
													{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].text : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].text === undefined || v.content[0].language[lang_id].text === '' ? v.content[0].text : v.content[0].language[lang_id].text))}
												</div>
												<div className="col-12 mt-3 mt-md-5 p-0">
													<a href={'#'} className="btn btn-primary shadow-sm" target="_blank" rel="noreferrer">
														{language[lang].btn_app}<i className="bi bi-chevron-right text-white"></i>
													</a>
												</div>
											</div>
										)
										})
									}
								</div>
							</div>
						</div>
						{/* <img alt='' className="img-fluid" src={repo_img_link("banner-snow.png")} style={{transform: `rotate(180deg)`, marginBottom: `-1px`}}/> */}
					
					</div>
				</div>
			</section>

			<section id="noticias" className="py-3 py-lg-5">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 offset-lg-1 col-lg-10 pb-3 pb-md-5">
							<div className="mb-4 text-center">
								<h3 className="titulo-pp">{language[lang].news_turistrela}</h3>
							</div>
							<div className="swiper-container slideshow">
								<Swiper modules={[Autoplay, Navigation, EffectFade, Pagination, Scrollbar, A11y]}
									speed={7000}
									loop={false}
									autoHeight={false}
									autoplay={{
										delay: 20000,
										disableOnInteraction: false,
									}}
									spaceBetween={15}
									slidesPerView={3}
								//navigation
									pagination={{
										el: '.swiper-pagination-noticias',
										clickable: true
									}}
								//scrollbar={{ draggable: true }}
								breakpoints={{
								// when window width is >= 0px
									0: {
										slidesPerView: 1,
									},
								// when window width is >= 768px
									768: {
										slidesPerView: 2,
									},
									820: {
										slidesPerView: 3,
									},
									992: {
										slidesPerView: 3,
									},
									1180: {
										slidesPerView: 3,
									},
									1200: {
										slidesPerView: 3,
									},
									1201: {
										slidesPerView: 3,
									},
								}}
								>
								{list_content.map((v, key) => {
									return (
									<SwiperSlide key={key}>
										<div className="noticia-item hover-animate shadow-sm">
											<a alt="" href={'./article/' + v.id}>
												<div className="noticia-img">
													{
													v.content[0].media['image'] !== undefined && v.content[0].media['image'][0] !== undefined ?
														<AssecImage 
															linkImage={v.content[0].media.image[0].link}
															width="450"
															height="300"
															fit='crop'
															type='img'
															sClass='img-fluid'
															alt={parse(v.content[0].title)} 
														/>
													:
														<img alt='' className="img-fluid" src={repo_img_link('nophoto.png')} />
													}
												</div>
											</a>
											<div className="p-3">
												<span className="small">{parse(v.info.date.published.split('T')[0])}</span>
												<div className="subtitulo-pp">
													{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
												</div>
												<div className="text-pp">
													{ReactHtmlParser((lang === 'pt' || v.content[0].language === undefined ? v.content[0].abstract : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].abstract === undefined || v.content[0].language[lang_id].abstract === '' ? v.content[0].abstract : v.content[0].language[lang_id].abstract)))}
												</div>
											</div>
											<div className="px-3 mb-3 text-right">
												<a className="btn btn-text" href={'./article/' + v.id}>{language[lang].keep_reading}<i className="bi bi-chevron-right"></i></a>
											</div>
										</div>
										{/* <IKImage
										path={v.content[0].media.image[0].link}
										transformation={[{
										height: 200,
										width: 200
										}]}
										/>  */}
									</SwiperSlide>
									)
									})}
								</Swiper>
							</div>
							<div className="swiper-pagination-noticias my-4 text-center"></div>
							<div className="col-12 mt-3 mt-md-5 text-center">
								<a className="btn btn-primary  shadow-sm" href={'./article/all'} >{language[lang].btn_news}</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="insights" className="py-3 py-lg-5 d-none">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12 offset-lg-1 col-lg-10 py-3 py-md-5">
							<div className="mb-4 text-center">
								<h3 className="titulo-pp">{language[lang].insights}</h3>
							</div>
							<div className="swiper-container slideshow">
								<Swiper modules={[Autoplay, Navigation, EffectFade, Pagination, Scrollbar, A11y]}
									speed={7500}
									loop={false}
									autoHeight={false}
									autoplay={{
										delay: 15000,
										disableOnInteraction: false,
									}}
									spaceBetween={15}
									slidesPerView={4}
									//navigation
										pagination={{
											el: '.swiper-pagination-insights',
											clickable: true
										}}
									//scrollbar={{ draggable: true }}
									breakpoints={{
										// when window width is >= 0px
										0: {
											slidesPerView: 1,
										},
										// when window width is >= 768px
										768: {
											slidesPerView: 2,
										},
										820: {
											slidesPerView: 3,
										},
										992: {
											slidesPerView: 3,
										},
										1180: {
											slidesPerView: 3,
										},
										1200: {
											slidesPerView: 3,
										},
										1201: {
											slidesPerView: 4,
										},
									}}
									>
									{list_insights.map((v, key) => {
									return (
										<SwiperSlide key={key}>
											<div className="insight-item hover-animate shadow-sm">
												<a alt="" href={'./insight/' + v.id}>
													<div className="insight-img">
														{
														v.content[0].media['image'] !== undefined && v.content[0].media['image'][0] !== undefined ?
															<AssecImage 
																linkImage={v.content[0].media.image[0].link}
																width="640"
																height="360"
																fit='crop'
																type='img'
																sClass='img-fluid'
																alt={parse(v.content[0].title)} 
															/>
														:
															<img alt='' className="img-fluid" src={repo_img_link('nophoto.png')} />
														}
													</div>
												</a>
												<div className="p-3">
													<span className="small">{parse(v.info.date.published.split('T')[0])}</span>
													<div className="subtitulo-pp">
														{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
													</div>
													<div className="text-pp">
														{ReactHtmlParser((lang === 'pt' || v.content[0].language === undefined ? v.content[0].abstract : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].abstract === undefined || v.content[0].language[lang_id].abstract === '' ? v.content[0].abstract : v.content[0].language[lang_id].abstract)))}
													</div>
												</div>
												<div className="px-3 mb-3 text-right">
													<a className="btn btn-text" href={'./insight/' + v.id}>{language[lang].keep_reading}<i className="bi bi-chevron-right"></i></a>
												</div>
											</div>
											{/* <IKImage
											path={v.content[0].media.image[0].link}
											transformation={[{
											height: 200,
											width: 200
											}]}
											/>  */}
										</SwiperSlide>
										)
									})}
								</Swiper>
							</div>
							<div className="swiper-pagination-insights my-4 text-center"></div>
							<div className="col-12 mt-3 mt-md-5 text-center">
								<a className="btn btn-primary  shadow-sm" href={'./insight/all'} >{language[lang].btn_insight}</a>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section id="opinion" className="py-3 py-lg-5 parallax" style={{ backgroundImage: `url(` + repo_img_link("banner-testemunhos.jpg") + `)` }}>
				<div className="container mp-mask">
					<div className="row">
						<div className="col-12 offset-md-1 col-md-10 offset-lg-2 col-lg-8 my-5">
							<div className="mb-4 mb-5 text-center">
								<h3 className="titulo-pp text-white">{language[lang].testimonials}</h3>
								<div className="col-12 col-md-8 offset-md-2 text-center">
									<p className="mt-4 text-white">{language[lang].opinion_banner_text}</p>
								</div>
							</div>
							<div className="col-12 text-center">
								<div className="swiper-container slideshow">
									<Swiper modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y, EffectFade]}
										speed={500}
										loop={false}
										autoHeight={true}

										autoplay={{
											delay: 10000,
											disableOnInteraction: false,
										}}

										spaceBetween={30}
										slidesPerView={1}
										effect={"fade"}
										//navigation
											pagination={{ 
											el: '.swiper-pagination-opinion',
											clickable: true 
											}}
											breakpoints={{
											// when window width is >= 0px
											0: {
												slidesPerView: 1,
											},
											// when window width is >= 768px
											768: {
												slidesPerView: 1,
											},
											820: {
												slidesPerView: 1,
											},
											992: {
												slidesPerView: 1,
											},
											1200: {
												slidesPerView: 1,
											},
										}}
										scrollbar={{ draggable: true }}
										>
										{dataTestimony.map((v, key) => {
											return (
												<SwiperSlide key={key}>
													{/* <blockquote className="blockquote">
														<i className="mb-0">{parse(v.content[0].message)}</i>
														<footer className="blockquote-footer mt-2 mt-md-4 text-center">{parse(v.content[0].name)}
														<span>{v.content[0].cargo}</span></footer>
													</blockquote> */}
													<div className='content p-4'>
								
														<div className="testimonial-text">
															{parse(v.content[0].message)}
														</div>
														<b className="text-white">- {parse(v.content[0].name)}</b>
														<span>{v.content[0].cargo}</span>
														{/* {
															v.content[0].address !== '' ?
															<b>
																({parse(v.content[0].address)})
															</b>
															: null
														} */}
													</div>
												</SwiperSlide>
											)
										})}
									</Swiper>
								</div>
								<div className="swiper-pagination-opinion my-3 text-center"></div>
							</div>
							<div className="col-12 mt-3 mt-md-5 text-center">
								<button type="button" /*onClick={() => setShow(true)}*/ data-toggle="modal" data-target={"#modal_form"} className="btn btn-primary-inv shadow-sm">{language[lang].btn_testimony}</button>
								<Modal onClose={() => setShow(false)} show={show} />
							</div>
						</div>
					</div>
				</div>
			</section>

			{
				list_companies.length > 0 ?
					<section id="companies-cards" className="py-3 py-lg-5">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12 offset-lg-1 col-lg-10 py-3 py-md-5">
									<div className="mb-4 text-center">
										<h3 className="titulo-pp">{language[lang].companies}</h3>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 offset-lg-1 col-lg-10">
									<div className="row">
										{
										list_companies.map((content) => {
											return (
												content.content.map((el, k) => {  
													return (
														<div key={k} className="col-6 col-md-3 my-3">
															{
															//el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
																<>
																	<div className="card company-item hover-animate">
																		<a href={'./company/' + content.id} title={el.title}>
																			<div className="company-img"> 
																				<AssecImage 
																					linkImage={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : repo_img_link('nophoto.webp')}
																					width="400"
																					height="300"
																					fit='crop'
																					type='img'
																					sClass='img-fluid'
																					alt={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['name'] : 'nophoto'}
																				/>
																				{/*<img src={el['media']['image'][0]['link']} className="img-fluid d-none" alt={el['media']['image'][0]['name']} />*/}
																			</div>
																			<div className="card-body pb-3">
																				<div className="card-title text-center">{el.title}</div>
																				<div className="card-text d-none">{ReactHtmlParser(el.abstract)}</div>
																			</div>
																		</a>
																		<div className="card-footer text-center d-none">
																			<a className="btn btn-text" href={'./company/' + content.id} title={language[lang].see_partner}>{language[lang].see_partner}<i className="bi bi-chevron-right"></i>
																			</a>
																		</div>
																	</div>
																</>
															/* : 
																<>
																	<div id="div-card" className="div-content col-12 my-3">
																		<div className={"div-title"}>
																			<h2 className={el.title !== '' ? "title" : "d-none"}>{el.title}</h2>
																		</div>
																		<div className="card-text">
																			{ReactHtmlParser(el.abstract)}
																		</div>

																		<div className="card-footer text-center d-none">
																			<a className="btn btn-text" href={'./company/' + content.id} title={language[lang].see_partner}>{language[lang].see_partner}<i className="bi bi-chevron-right"></i></a>
																		</div>
																	</div>
																</> */
															}
														</div> 
													)
												})
											)
										})
										}
									</div>
								</div>
							</div>
						</div>
					</section>
				: null
			}


			{
				dataBusiness.length > 0 && 0 ?
					<section id="parceiros" className="d-none py-3 py-lg-5">
						<div className="container">
							<div className="row">
								<div className="col-12 py-3 py-md-5">
									{
									dataBusiness.map((v, key) => {
										//console.log('------------> V', v)
										return (
											<div key={key}>
												<div className="mb-4 text-center">
													<h3 className="titulo-pp">
														{/*{language[lang].project}*/}
														{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title))}
													</h3>

													<p className="text-center pb-3">
														{parse(lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle))}
													</p>
												</div>
												{v.content.map((el, k) => {
													return(
														k > 0 ?
															<div key={k} id="parceiros_list">
																<div className="col-12 d-none d-md-block mt-5">
																	{k % 2 === 0 ?
																		<div className="row mb-5">
																			<div className="col-xs-12 col-sm-5 col-image boxshadowtwobottom">
																				{
																				el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
																				<div className="grid">
																					<figure className="effect-apollo">
																						<div className="img rounded">
																							<a
																								href={el['media']['image'][0]['link']}
																								data-fancybox={"gallery_" + k}
																								data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
																							>
																								<AssecImage 
																									linkImage={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : repo_img_link('nophoto.png')}
																									width="640"
																									height="360"
																									fit='fit'
																									type='img'
																									sClass='img-fluid'
																									alt={el['media']['image'][0]['name']}
																								/>
																								{/*<img src={el['media']['image'][0]['link']} className="img-fluid d-none" alt={el['media']['image'][0]['name']} />*/}
																								<figcaption></figcaption>
																							</a>

																							{
																							el['media']['image'].map((el_gal, k_gal) => {
																								if(k_gal === 0) {
																									return null
																								}
																								else {
																									return (
																										<a
																										key={k_gal}
																										href={el_gal['link']}
																										data-fancybox={"gallery_" + k}
																										//data-caption={el_gal['link'].includes(el_gal['name']) ? '' : el_gal['name']}
																										data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}

																									>
																										<AssecImage 
																											linkImage={el_gal['link']}
																											width="640"
																											height="360"
																											fit='crop'
																											type='img'
																											sClass="img-fluid d-none" 
																											alt={el_gal['name']}
																										/>
																										{/*<img src={el_gal['link']} className="img-fluid d-none" alt={el_gal['name']} />*/}
																									</a>
																									)
																								}
																							})
																							}
																						</div>
																					</figure>
																				</div>
																				:
																				""
																				}
																			</div>
																			<div className="col-xs-12 col-sm-7 col-text mt-4 mt-md-0 flex-align">
																				<div className="pl-sm-4 pl-lg-5">
																					<div>
																						<h3 className="titulo-pp text-center mb-4">
																							{/*{language[lang].project}*/}
																							{parse(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
																						</h3>
																						<h4 className="subtitulo-pp text-center pb-3">
																							{parse(lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle))}
																						</h4>
																						{ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
																					</div>
																					{/*<div className="text-center pt-4"><a className="btn btn-text" href={'./pag/' + v.id + '/' + k} >{language[lang].see_partner}<i className="bi bi-chevron-right"></i></a></div>*/}
																				</div>
																			</div>
																		</div>
																	:
																		<div className="row mb-5">
																			<div className="col-xs-12 col-sm-7 col-text mt-4 mt-md-0 flex-align">
																				<div className="pr-sm-4 pr-lg-5">
																					<div>
																						<h3 className="titulo-pp text-center mb-4">
																							{/*{language[lang].project}*/}
																							{parse(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
																						</h3>
																						<h4 className="subtitulo-pp text-center pb-3">
																							{parse(lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle))}
																						</h4>
																						{ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
																					</div>
																					{/*<div className="text-center pt-4"><a className="btn btn-text" href={'./pag/' + v.id + '/' + k} >{language[lang].see_partner}<i className="bi bi-chevron-right"></i></a></div>*/}
																				</div>
																			</div>
																			<div className="col-xs-12 col-sm-5 col-image boxshadowtwobottom">
																				{
																					el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
																					<div className="grid">
																						<figure className="effect-apollo">
																							<div className="img rounded">
																								<a
																									href={el['media']['image'][0]['link']}
																									data-fancybox={"gallery_" + k}
																									data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
																								>
																									<AssecImage 
																										linkImage={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : ''}
																										width="640"
																										height="360"
																										fit='crop'
																										type='img'
																										sClass='img-fluid'
																										alt={el['media']['image'][0]['name']}
																									/>
																									{/*<img src={el['media']['image'][0]['link']} className="img-fluid d-none" alt={el['media']['image'][0]['name']} />*/}
																									<figcaption></figcaption>
																								</a>

																								{
																								el['media']['image'].map((el_gal, k_gal) => {
																									if(k_gal === 0) {
																										return null
																									}
																									else {
																										return (
																											<a
																											key={k_gal}
																											href={el_gal['link']}
																											data-fancybox={"gallery_" + k}
																											//data-caption={el_gal['link'].includes(el_gal['name']) ? '' : el_gal['name']}
																											data-caption={(el_gal['link'].includes(el_gal['name']) || el_gal['name'].includes('webp') || el_gal['name'].includes('jpg') || el_gal['name'].includes('png')) ? '' : el_gal['name']}
																										>
																											<AssecImage 
																												linkImage={el_gal['link']}
																												width="640"
																												height="360"
																												fit='crop'
																												type='img'
																												sClass="img-fluid d-none" 
																												alt={el_gal['name']}
																											/>
																											{/*<img src={el_gal['link']} className="img-fluid d-none" alt={el_gal['name']} />*/}
																										</a>
																										)
																									}
																								})
																								}
																							</div>
																						</figure>
																					</div>
																					:
																					""
																				}
																			</div>
																		</div>
																	}
																</div>
																<div className="col-12 mb-5 d-md-none">
																	<div className="">
																		{
																			el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 0 ?
																			<div className="img rounded">
																				<a
																					href={el['media']['image'][0]['link']}
																					data-fancybox={"gallery_" + k}
																					data-caption={el['media']['image'][0]['link'].includes(el['media']['image'][0]['name']) ? '' : el['media']['image'][0]['name']}
																				>
																					<AssecImage 
																						linkImage={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : repo_img_link('nophoto.png')}
																						width="480"
																						height="360"
																						fit='crop'
																						type='img'
																						sClass='img-fluid'
																						alt={el['media']['image'][0]['name']}
																					/>
																					{/*<img src={el['media']['image'][0]['link']} className="img-fluid" alt={el['media']['image'][0]['name']} />*/}
																				</a>
																				
																				{
																				el['media']['image'].map((el_gal, k_gal) => {
																					if(k_gal === 0) {
																						return null
																					}
																					else {
																						return (
																							<a
																							key={k_gal}
																							href={el_gal['link']}
																							data-fancybox={"gallery_" + k}
																							data-caption={el_gal['link'].includes(el_gal['name']) ? '' : el_gal['name']}
																						>
																							<AssecImage 
																								linkImage={el_gal['link']}
																								width="640"
																								height="360"
																								fit='crop'
																								type='img'
																								sClass="img-fluid d-none" 
																								alt={el_gal['name']}
																							/>
																							{/*<img src={el_gal['link']} className="img-fluid d-none" alt={el_gal['name']} />*/}
																						</a>
																						)
																					}
																				})
																				}
																			</div>
																			:
																			""
																		}
																	</div>
																	<div className="col-text mt-5 mt-md-4 mt-md-0">
																		<div>
																			<h3 className="titulo-pp text-center mb-4">
																				{/*{language[lang].project}*/}
																				{parse(lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title))}
																			</h3>
																				<h4 className="subtitulo-pp text-center pb-3">
																				{parse(lang === 'pt' || el.language === undefined ? el.subtitle : (el.language[lang_id] === undefined || el.language[lang_id].subtitle === undefined || el.language[lang_id].subtitle === '' ? el.subtitle : el.language[lang_id].subtitle))}
																			</h4>
																			{ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
																		</div>
																		{/*<div className="text-center pt-4"><button className="btn btn-text" href={'./pag/' + v.id + '/' + k} >{language[lang].see_partner}<i className="bi bi-chevron-right"></i></button></div>*/}
																	</div>
																</div>
															</div>
														:
															''
												)
											})}
											</div>
											)
									})
									}
								</div>
							</div>
						</div>
					</section>
				: null
			}

			{
				list_partners.length > 0 && 0 ?
					<section id="partners-cards" className="py-3 py-lg-5">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12 offset-lg-1 col-lg-10 py-3 py-md-5">
									<div className="mb-4 text-center">
										<h3 className="titulo-pp">{language[lang].partners}</h3>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-12 offset-lg-1 col-lg-10">
									<div className="row">
										{
										list_partners.map((content) => {
											return (
												content.content.map((el, k) => {  
													return (
														<div key={k} className="col-6 col-md-3 col-lg-3 col-xl-2 my-3">
															{
															el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
																<>
																	<div className="card partner-item hover-animate">
																		<a href={'./partner/' + content.id} title={el.title}>
																			<div className="partner-img"> 
																				<AssecImage 
																					linkImage={el['media']['image'] !== undefined && el['media']['image'][0] !== undefined ? el['media']['image'][0]['link'] : repo_img_link('nophoto.png')}
																					width="240"
																					height="180"
																					fit='crop'
																					type='img'
																					sClass='img-fluid'
																					alt={el['media']['image'][0]['name']}
																				/>
																				{/*<img src={el['media']['image'][0]['link']} className="img-fluid d-none" alt={el['media']['image'][0]['name']} />*/}
																			</div>
																			<div className="card-body pb-3 d-none">
																				<div className="card-title text-center d-none">{el.title}</div>
																				<div className="card-text">{ReactHtmlParser(el.abstract)}</div>
																			</div>
																		</a>
																		<div className="card-footer text-center d-none">
																			<a className="btn btn-text" href={'./partner/' + content.id} title={language[lang].see_partner}>{language[lang].see_partner}<i className="bi bi-chevron-right"></i>
																			</a>
																		</div>
																	</div>
																</>
															: 
																<>
																	<div id="div-card" className="div-content col-12 my-3">
																		<div className={"div-title"}>
																			<h2 className={el.title !== '' ? "title" : "d-none"}>{el.title}</h2>
																		</div>
																		<div className="card-text">
																			{ReactHtmlParser(el.abstract)}
																		</div>

																		<div className="card-footer text-center d-none">
																			<a className="btn btn-text" href={'./partner/' + content.id} title={language[lang].see_partner}>{language[lang].see_partner}<i className="bi bi-chevron-right"></i></a>
																		</div>
																	</div>
																</>
															}
														</div> 
													)
												})
											)
										})
										}
									</div>
								</div>
							</div>
						</div>
					</section>
				: null
			}

			<section id="newsletter" className="py-4 d-none">
				<div className="container">
				<div className="row">
					<div className="col-12 col-md-7">
					<h3 className="titulo-pp">{language[lang].newsletter}</h3>
					<div className="d-none d-lg-block">{language[lang].newsletter_banner_text}</div>
					</div>
					
					<div className="col-12 col-md-5 flex-align">
						<div className="w-100">
						<div className="input-group">
							<input type="email" name="email"
								className="form-control"
								placeholder=""
								//value={dados.current.content.email}
								//onChange={handle_change_info}
								required
							/>
							<button type="submit" className="btn-tertiary" data-dismiss="modal">{language[lang].btn_subscribe}</button>
						</div>
						<label htmlFor="terms">
							<input type="checkbox" name="terms" id="terms"
								className="my-2 mr-2"
								placeholder=""
								required
							/>
							<label htmlFor="terms">{language[lang].agree_terms_conditions}</label> <a href={Hosts.WEB_SITE_URI + "terms-conditions"} target="_blank" rel="noreferrer">{language[lang].terms_conditions_link}</a>
							{/*language[lang].accept_terms*/}
						</label>
						</div>
					</div>
				</div>
				</div>
			</section>

      	</main>

      	<Footer />
    </div>

  )
}

export default Pagina;