import React, { useState, useEffect, useRef } from 'react'
import { useParams, Redirect } from "react-router-dom";

import "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import NormalContent from './Normal';
import CardContent from './Card';
import NewsCardsContent from './NewsCards';
import InsightCardsContent from './InsightCards';
import PartnerCardsContent from './PartnerCards';
import CompanyCardsContent from './CompanyCards';
import GalleryCardsContent from './GalleryCards';
import RoutesCardsContent from './RoutesCards';
import InterestPointCardsContent from './InterestPointCards';
import AccordionContent from './Accordion';
import TabContent from './Tabs';
import NewsContent from './News';
import InsightContent from './Insight';
import PartnerContent from './Partner';
import CompanyContent from './Company';
import GalleryContent from './Gallery';
import RoutesContent from './Routes';
import InterestPointContent from './InterestPoint';
import ContactContent from './ContactForm';

//import ContactContent from '../Forms/ContactForm';
import FormCareer from './Careers';

import ListLeftContent from './ListLeft';
import ListRightContent from './ListRight';
import ListAltContent from './ListAlt';
import ListBannerContent from './ListBanner';
import SubPageContent from './SubPage';
import SplitPageContent from './SplitPage';

import WebinarCardsContent from './WebinarCards';
import WebinarContent from './Webinar';
import WorkshopCardsContent from './WebinarCards';
import WorkshopContent from './Workshop';
import SeminarCardsContent from './SeminarCards';
import SeminarContent from './Seminar';
import ConferenceCardsContent from './SeminarCards';
import ConferenceContent from './Conference';
//import AtividadesContent from './Activity';

import NotFound from './NotFound';

import Loader from '../../../loader';

import * as Hosts from '../../../Hosts';
import { reqGET } from '../../../Utils';
import { getTokenInfo } from '../../../UseToken';

import './Content.css';

export default function Content() {

    const { pag_id, slug, article_id, gallery_id, preview_id, subpage_id, routes_id, interest_id, insight_id, partner_id, company_id, form_slug, webinar_id, workshop_id, seminar_id, conference_id } = useParams();
    const [isloading, setLoading] = useState(true);

    // const data_interface = {
    //     id: '',
    //     info: {},
    //     content: {}
    // }

    const [, set_content] = useState(null);
	const content = useRef(null);

    //const [content, set_content] = useState(data_interface);

    // const clean_data = () => {
	// 	// DEEP COPY OF data_interface 
	// 	set_content(content.current = JSON.parse(JSON.stringify(data_interface)))
	// }

    useEffect(() => {

        let getPreviewInfoId = async (id) => {
            await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        if(getTokenInfo()['profile'] !== '') {
                            res.data[0].info['preview'] = true

                            set_content(content.current = res.data[0])
                            setLoading(false)
                        }
                        else {
                        }
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoId = async (id) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${id}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            //res.data[0].content[0] = {text: 'rascunho ou agendado'}
                            res.data[0].info.layout = '404'
                        }

                        set_content(content.current = res.data[0])

                        return true
                        //set_content(res.data[0])
                    }
                    else {
                        if(article_id !== undefined && article_id !== 'undefined' && article_id !== '') {
                            set_content(content.current = {id: id, info: {type: 'news', layout: '404'}, content: []})
                        }
                        else {
                            getInfoSlug(id)
                                .then((res) => {
                                    if(res === true){
                                        setLoading(false)
                                    }
                                })
                        }
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        let getInfoSlug = async (slug) => {
            return await reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${slug}`)
                .then(res => { 
                    if(res.data.length > 0) {

                        var hoje = new Date();
                        let status = 0
                        if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                            var date_p = new Date(res.data[0].info.date.published);

                            status = 1
                            if(hoje >= date_p) 
                                status = 2
                        }

                        if(status !== 2) {
                            res.data[0].info.layout = '404'
                        }
                        set_content(content.current = res.data[0])
                        
                        return true
                    }
                    else {
                        set_content(content.current = {id: '', info: {type: 'content', layout: '404'}, content: []})

                        return true
                    }
                })
                .catch(erro => console.log('ERROR', erro))
        }

        //clean_data()

        if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
            getPreviewInfoId(preview_id)
               .then(() => {
               })
        }
        else if(pag_id !== undefined && pag_id !== 'undefined' && pag_id !== '') {
            getInfoId(pag_id)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
            getInfoSlug(slug)
                .then((res) => {
                    if(res === true){
                        setLoading(false)
                    }
                })
        }
        else if(article_id !== undefined && article_id !== 'undefined' && article_id !== '')  {
            if(article_id === 'all') {
                //content.current.info['type'] = 'news'
                //content.current.info['layout'] = 'all'
                
                set_content(content.current = {id: '', info: {type: 'news', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(article_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(insight_id !== undefined && insight_id !== 'undefined' && insight_id !== '')  {
            if(insight_id === 'all') {
                // content.current.info['type'] = 'insight'
                // content.current.info['layout'] = 'all'

                set_content(content.current = {id: '', info: {type: 'insight', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(insight_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(partner_id !== undefined && partner_id !== 'undefined' && partner_id !== '')  {
            if(partner_id === 'all') {
                //content.current.info['type'] = 'partner'
                // content.current.info['layout'] = 'all'

                set_content(content.current = {id: '', info: {type: 'partner', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(partner_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(company_id !== undefined && company_id !== 'undefined' && company_id !== '')  {
            if(company_id === 'all') {
                //content.current.info['type'] = 'company'
                // content.current.info['layout'] = 'all'

                set_content(content.current = {id: '', info: {type: 'company', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(company_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(routes_id !== undefined && routes_id !== 'undefined' && routes_id !== '')  {
            if(routes_id === 'all') {
                //content.current.info['type'] = 'routes'
                // content.current.info['layout'] = 'all'

                set_content(content.current = {id: '', info: {type: 'routes', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(routes_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(interest_id !== undefined && interest_id !== 'undefined' && interest_id !== '')  {
            if(interest_id === 'all') {
                //content.current.info['type'] = 'interest'
                // content.current.info['layout'] = 'all'

                set_content(content.current = {id: '', info: {type: 'interest', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(interest_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(form_slug !== undefined && form_slug !== 'undefined' && form_slug !== '')  {
            if(form_slug === 'carreiras') {
                //content.current.info['type'] = 'interest'
                // content.current.info['layout'] = 'all'

                set_content(content.current = {id: '', info: {type: 'form', layout: 'career'}, content: []})

                //console.log(content.current)
                setLoading(false)
            }
        }
        else if(gallery_id !== undefined && gallery_id !== 'undefined' && gallery_id !== '')  {
            if(gallery_id === 'all') {
                
                set_content(content.current = {id: '', info: {type: 'gallery', layout: 'all'}, content: []})
                setLoading(false)

            }
            else {
                getInfoId(gallery_id)
                    .then(() => {
                        setLoading(false)
                    })
            }
        }
        else if(webinar_id !== undefined && webinar_id !== 'undefined' && webinar_id !== '')  {
            if(webinar_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'webinar', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(webinar_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(workshop_id !== undefined && workshop_id !== 'undefined' && workshop_id !== '')  {
            if(workshop_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'workshop', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(workshop_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(seminar_id !== undefined && seminar_id !== 'undefined' && seminar_id !== '')  {
            if(seminar_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'workshop', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(seminar_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }
        else if(conference_id !== undefined && conference_id !== 'undefined' && conference_id !== '')  {
            if(conference_id === 'all') {
                set_content(content.current = {id: '', info: {type: 'workshop', layout: 'all'}, content: []})
                setLoading(false)
            }
            else {
                getInfoId(conference_id)
                    .then((res) => {
                        if(res === true){
                            setLoading(false)
                        }
                    })
            }
        }

    }, [pag_id, slug, article_id, routes_id, gallery_id, interest_id, insight_id, preview_id, partner_id, company_id, form_slug, webinar_id, workshop_id, seminar_id, conference_id]);

    return (isloading ?

		<Loader />
		
		:

        (() => {

            switch (content.current.info.type) {
                case 'content':
                    switch (content.current.info.layout) {

                        case 'card':
                            return (
                                <CardContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case 'accordion':
                            return (
                                <AccordionContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case 'tabs':
                            return (
                                <TabContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'contact':
                            return (
                                <ContactContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'list-left':
                            return (
                                <ListLeftContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 

                        case 'list-right':
                            return (
                                <ListRightContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 

                        case 'list-alt':
                            return (
                                <ListAltContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 

                        case 'list-banner':
                            return (
                                <ListBannerContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 
                        
                        case 'subpage':
                            return (
                                <SubPageContent info={{ state: { id: pag_id, preview: preview_id, slug: slug, subpage: subpage_id } }} />
                            ) 
                        
                        case 'splitpage':
                            return (
                                <SplitPageContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: pag_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'news':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <NewsCardsContent info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <NewsContent info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: article_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'gallery':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <GalleryCardsContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <GalleryContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: gallery_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'insight':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <InsightCardsContent info={{ state: { id: insight_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <InsightContent info={{ state: { id: insight_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: insight_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: insight_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'routes':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <RoutesCardsContent info={{ state: { id: routes_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <RoutesContent info={{ state: { id: routes_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: routes_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: routes_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'interest':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <InterestPointCardsContent info={{ state: { id: interest_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <InterestPointContent info={{ state: { id: interest_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: interest_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: interest_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'partner':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <PartnerCardsContent info={{ state: { id: partner_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <PartnerContent info={{ state: { id: partner_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: partner_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: partner_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'company':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <CompanyCardsContent info={{ state: { id: company_id, preview: preview_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <CompanyContent info={{ state: { id: company_id, preview: preview_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: company_id, preview: preview_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: company_id, preview: preview_id, slug: slug } }} />
                            )
                    }

                case 'form':
                    switch (content.current.info.layout) {

                        case 'career':
                            return (
                                <FormCareer info={{ state: { id: '', preview: '', slug: form_slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: '', preview: '', slug: form_slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: '', preview: '', slug: form_slug } }} />
                            )
                    }

                case 'webinar':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <WebinarCardsContent info={{ state: { id: webinar_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <WebinarContent info={{ state: { id: webinar_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: webinar_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: webinar_id, slug: slug } }} />
                            )
                    }

                case 'workshop':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <WorkshopCardsContent info={{ state: { id: workshop_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <WorkshopContent info={{ state: { id: workshop_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: workshop_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: workshop_id, slug: slug } }} />
                            )
                    }

                case 'seminar':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <SeminarCardsContent info={{ state: { id: seminar_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <SeminarContent info={{ state: { id: seminar_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: seminar_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: seminar_id, slug: slug } }} />
                            )
                    }

                case 'conference':
                    switch (content.current.info.layout) {
                        case 'all':
                            return (
                                <ConferenceCardsContent info={{ state: { id: conference_id, slug: slug } }} />
                            )

                        case 'normal':
                            return (
                                <ConferenceContent info={{ state: { id: conference_id, slug: slug } }} />
                            )
                        
                        case '404':
                            return (
                                <NotFound info={{ state: { id: conference_id, slug: slug } }} />
                            ) 

                        default:
                            return (
                                <NormalContent info={{ state: { id: conference_id, slug: slug } }} />
                            )
                    }

                default:
                    return <Redirect to="/" />
            }
        })()
    
    )
}
