import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// import {
//     FacebookIcon,
//     FacebookShareButton,
// } from "react-share";


// import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox.css";

import ReactHtmlParser/*, { processNodes, convertNodeToElement, htmlparser2 }*/ from 'react-html-parser';
import { Helmet } from 'react-helmet';

import * as Hosts from '../../../Hosts';
import { reqGET, content_language, repo_img_link } from '../../../Utils';

import Loader from '../../../loader';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import Preview from '../Preview/Preview';
import Breadcrumb from '../Breadcrumb/Breadcrumb';

import AssecImage from "../ASSEC_Image/Image"

// import DivSharer from '../DivSharer/DivSharer';

import { language } from '../WEBContent/Language';

export default function Insight(props) {
    const { pathname } = useLocation();

    const [isloading, setLoading] = useState(true);

	const [lang, set_lang] = useState(localStorage.getItem('lang'));
	const [lang_id, set_lang_id] = useState('1');

    const [link, set_link] = useState('')

    const data_interface = {
        id: '',
        info: {},
        content: {}
    }
    const [content, set_content] = useState(data_interface);

    useEffect(() => {

        try {
			let id = props.info.state.id;
			let slug = props.info.state.slug;
			let preview_id = props.info.state.preview;

            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                set_lang_id(el.id)
                }
            })

            if(id !== '') {
        
                let getPreviewInfoId = async (value) => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${value}`)
                        .then(res => { 
                            if(res.data.length > 0) {
                                res.data[0].info['preview'] = true

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
       
                                if(res.data[0].info.preview === true) {
                                    //res.data[0].content = {text: 'preview'}
                                    console.log('Normal Preview')
        
                                    set_content(res.data[0])
                                    setLoading(false)   
                                }
                                else if(status !== 2) {
                                    res.data[0].content = {text: ''}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }

                let getInfoId = async (value) => {
                    await reqGET(`${Hosts.SIMCore_API}/web/content/${Hosts.companycode}/${value}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                if(res.data[0].content[0].contacts !== undefined && res.data[0].content[0].contacts['website'] !== undefined && res.data[0].content[0].contacts['website'] !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].contacts['website'].includes('http') ? res.data[0].content[0].contacts['website'] : 'http://' + res.data[0].content[0].contacts['website'])
                                    set_link(hostname)
                                }

                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2) {
                                    res.data[0].content = {text: ''}
        
                                    set_content(res.data[0])
                                    setLoading(false)    
                                }
                                else {
                                    set_content(res.data[0]) 
            
                                    setLoading(false) 
                                }
                            }
                            else {
                                getInfoSlug(value)
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
            
                let getInfoSlug = async (value) => {
                    reqGET(`${Hosts.SIMCore_API}/web/content/slug/${Hosts.companycode}/${value}`)
                        .then(res => { 
                            if(res.data.length > 0) {
        
                                if(res.data[0].content[0].contacts !== undefined && res.data[0].content[0].contacts['website'] !== undefined && res.data[0].content[0].contacts['website'] !== '') {
                                    const { hostname } = new URL(res.data[0].content[0].contacts['website'].includes('http') ? res.data[0].content[0].contacts['website'] : 'http://' + res.data[0].content[0].contacts['website'])
                                    set_link(hostname)
                                }
        
                                var hoje = new Date();
                                let status = 0
                                if(res.data[0].info.date.published !== undefined && res.data[0].info.date.published !== '') {
                                    var date_p = new Date(res.data[0].info.date.published);
        
                                    status = 1
                                    if(hoje >= date_p) 
                                        status = 2
                                }
        
                                if(status !== 2)  {
                                    res.data[0].content = [{text: ''}]
        
                                    set_content(res.data[0])
                                    setLoading(false)
                                }
                                else {
                                    set_content(res.data[0]) 
        
                                    setLoading(false) 
                                }
                            }
                        })
                        .catch(erro => console.log('ERROR', erro))
                }
        
                if(preview_id !== undefined && preview_id !== 'undefined' && preview_id !== '') {
                    getPreviewInfoId(preview_id)
                }
                else if(id !== undefined && id !== 'undefined' && id !== '') {
                    getInfoId(id)
                }
                else if(slug !== undefined && slug !== 'undefined' && slug !== '')  {
                    getInfoSlug(slug)
                }
            }
            else {
                console.log('=== ""')
            }

            return

        } catch {
            console.log('nada')

			return
		}
        
    }, [props.info.state.id, props.info.state.preview, props.info.state.slug]);

    const get_seo_data = (type) => {

        if(type === 'title') {
            if(content.info.seo !== undefined && content.info.seo['title'] !== undefined  && content.info.seo['title'] !== '') {
                return content.info.seo['title']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_TITLE
            }
        }
        else if(type === 'description') {
            if(content.info.seo !== undefined && content.info.seo['description'] !== undefined  && content.info.seo['description'] !== '') {
                return content.info.seo['description']
            } 
            else if(content.content !== undefined && content.content.length > 0 && content.content[0]['abstract'] !== undefined && content.content[0]['abstract'] !== undefined  && content.content[0]['abstract'] !== '') {
                return content.content[0]['abstract']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_DESCRIPTION
            }
        }
        if(type === 'keywords') {
            if(content.info.seo !== undefined && content.info.seo['keywords'] !== undefined  && content.info.seo['keywords'] !== '') {
                return content.info.seo['keywords']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_KEYWORDS
            }
        }
        else if(type === 'image') {
            if(content.content !== undefined && content.content.length > 0 && content.content[0]['media'] !== undefined && content.content[0]['media']['image'] !== undefined && content.content[0]['media']['image'].length > 0 && content.content[0]['media']['image'][0]['link'] !== '') {
                return content.content[0]['media']['image'][0]['link']
            } 
            else {
                return Hosts.SITES.SIMWEBSITE.DEFAULT_SEO_IMAGE
            }
        }
        else if(type === 'link') {
            let title_seo = Hosts.WEB_SITE_URI + pathname
            return '.' + title_seo.replace("//", "/");
        }
    }

    return (isloading ?

		<Loader />
		
		:

        <>
            {
            content.info['preview'] === true ? 
                <Preview />
            : 
                null
            }
            <Navbar />
            <Breadcrumb />
            <main className="article" id="partner">
                <div>
                    <Helmet>
                        <title>{ get_seo_data('title') }</title>
                        <link rel="canonical" href={ get_seo_data('link') } />
                        <meta name="description" content={ get_seo_data('description') } />
                        <meta name="keywords" content={ get_seo_data('keywords') } />
                        <meta property="og:url" content={ get_seo_data('link') } />
                        <meta property="og:title" content={ get_seo_data('title') }></meta>
                        <meta property="og:description" content={ get_seo_data('description') }></meta>
                        <meta property="og:image" content={ get_seo_data('image') }></meta>
                        <meta name="twitter:title" content={ get_seo_data('title') }></meta>
                        <meta name="twitter:description" content={ get_seo_data('description') }></meta>
                        <meta name="twitter:image" content={ get_seo_data('image') }></meta>
                    </Helmet>
                </div>

                {
                content.content.map((el, k) => {  
                    return (
                        <section key={k} className="content-body">
                            <div className="container">
                                <div className="row">
                                    <div className="px-3 my-3 text-right w-100">
                                        <a className="btn btn-text" href={'./partner/all'} ><i className="bi bi-chevron-left"></i> {language[lang].back}</a>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className='col-12'>
                                        <div className='row'>
                                            <div className="div-content col-12 col-md-9 col-lg-8 order-last order-md-first pr-md-4 pr-lg-5">
                                                <div className={"div-title"}>
                                                    <h1 className={el.title !== '' ? "titulo-primario" : "d-none"}>
                                                        {lang === 'pt' || el.language === undefined ? el.title : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].title === undefined || el.language[lang_id].title === '' ? el.title : el.language[lang_id].title)}
                                                    </h1>
                                                </div>

                                                <div className={"div-text py-3 py-md-3"}>
                                                    <p style={{display: 'inline-flex', flexWrap: 'nowrap', flexDirection: 'column'}}>
                                                        
                                                        {
                                                        el.contacts['morada'] !== undefined && el.contacts.morada !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start'}} className="mb-2"><i className="bi bi-house mr-2"></i>
                                                                {ReactHtmlParser(el.contacts.morada.replaceAll('\n', '<br/>'))}
                                                            </span>
                                                        :
                                                            null
                                                        } 
                                                        {
                                                            el.contacts.website !== '' ?
                                                                <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}>
                                                                    <i className="bi bi-globe mr-2"></i> 
                                                                    <a href={el.contacts.website.includes('http') ? el.contacts.website : 'http://' + el.contacts.website} alt="" target="_blank" rel="noreferrer">
                                                                        {
                                                                        link
                                                                        //el.contacts.website.includes(el.contacts.website_type) ? el.contacts.website : el.contacts.website_type + el.contacts.website
                                                                        }
                                                                    </a>
                                                                </span>
                                                                :
                                                            null
                                                        }
                                                        {
                                                        el.contacts.email !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-envelope mr-2"></i>

                                                            <a href={'mailto: '+ el.contacts.email} alt="" target="_blank" rel="noreferrer">{el.contacts.email}</a>


                                                            </span>
                                                        :
                                                            null
                                                        } 
                                                        {
                                                        content.info.category !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}} className='d-none'><strong>{content.info.category}</strong></span>
                                                        :
                                                            null
                                                        }
                                                        {
                                                        el.contacts.tlf !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-telephone mr-2"></i> <a href={"callto:" + el.contacts.tlf}>{el.contacts.tlf}</a></span>
                                                        :
                                                            null
                                                        }
                                                        {
                                                        el.contacts.tlm !== '' ?
                                                            <span style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', lineHeight: '2'}}><i className="bi bi-phone mr-2"></i> <a href={"callto: " + el.contacts.tlm}>{el.contacts.tlm}</a> <br /></span>
                                                        :
                                                            null
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className={"div-image col-12 col-md-3 col-lg-4 order-first order-md-last mt-3 mb-5"}>
                                                <div className="card partner-item hover-animate">
                                                    {
                                                        el['media'] !== undefined && el['media']['image'] !== undefined && el['media']['image'].length > 0 ?
                                                    
                                                            <a
                                                                href={el['media']['image'][0]['link']}
                                                                data-fancybox="gallery"
                                                                data-caption={(el['media']['image'][0]['link'] === el['media']['image'][0]['name'] || el['media']['image'][0]['name'].includes('webp') || el['media']['image'][0]['name'].includes('jpg') || el['media']['image'][0]['name'].includes('png')) ? '' : el['media']['image'][0]['name']}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={el['media']['image'][0]['link']}
                                                                    width="400"
                                                                    height="300"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid rounded shadow-sm" 
                                                                    alt={el['media']['image'][0]['name']}
                                                                />
                                                                {/*<img src={el['media']['image'][0]['link']} className="img-fluid rounded shadow-sm" alt={el['media']['image'][0]['name']} />*/}
                                                            </a>
                                                        :
                                                            <a
                                                                href={repo_img_link('nophoto.webp')}
                                                                data-fancybox="gallery"
                                                                data-caption={'nophoto'}
                                                            >
                                                                <AssecImage 
                                                                    linkImage={repo_img_link('nophoto.webp')}
                                                                    width="400"
                                                                    height="300"
                                                                    fit='crop'
                                                                    type='img'
                                                                    sClass="img-fluid rounded shadow-sm" 
                                                                    alt={'nophoto'}
                                                                />
                                                            </a>
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='div-content col-12'>

                                        <div className={"div-abstract mt-3 d-none"}>
                                            <p>
                                                {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.abstract : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].abstract === undefined || el.language[lang_id].abstract === '' ? el.abstract : el.language[lang_id].abstract))}
                                            </p>
                                        </div>

                                        <div className="div-categories w-100 d-none"><i className="bi bi-tag" />
                                            {
                                            content.info.tags.map((v, k) => {
                                                return (
                                                    <span key={k} className="pill-tag">{v.text}</span>
                                                )
                                            })
                                            }
                                        </div>
                                        <div className={"div-text pb-4 pb-md-5"}>
                                            {ReactHtmlParser(lang === 'pt' || el.language === undefined ? el.text : (el.language[lang_id] === undefined || el.language[lang_id] === null || el.language[lang_id].text === undefined || el.language[lang_id].text === '' ? el.text : el.language[lang_id].text))}
                                        </div>
                                    </div>

                                    {
                                    el['media'] !== undefined && el['media']['image'] !== undefined && el.media.image.length > 1 ?
                                        <div className="div-content col-12 mb-5 order-last">
                                            <div className="row div-gallery">
                                                {
                                                el.media.image.map((el_img, k_img) => {
                                                    if(k_img > 0) {

                                                        return (
                                                            <div key={k_img} className="col-6 col-sm-3 col-lg-2 p-0">
                                                                <a
                                                                    href={el_img.link}
                                                                    data-fancybox="gallery"
                                                                    data-caption={(el_img.link === el_img.name || el_img.name.includes('webp') || el_img.name.includes('jpg') || el_img.name.includes('png')) ? '' : el_img.name}
                                                                >
                                                                    <figure className="shadow-sm hover-animate m-2">
                                                                        <AssecImage 
                                                                            linkImage={el_img.link}
                                                                            width="400"
                                                                            height="400"
                                                                            fit='fit'
                                                                            type='img'
                                                                            sClass="img-fluid" 
                                                                            alt={el_img.name}
                                                                        />
                                                                        {/*<img src={el_img.link} className="img-fluid" alt={el_img.name} />*/}
                                                                        <figcaption>{el_img.description}</figcaption>
                                                                    </figure>
                                                                </a>
                                                            </div>
                                                        )
                                                    }
                                                    else
                                                        return null
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                    {
                                    el['media'] !== undefined && el['media']['doc'] !== undefined && el.media.doc.length > 0 ?
                                        <div className="div-content col-12 mb-5 order-last">
                                            <div className="row div-documents">
                                                {
                                                el.media.doc.map((el_doc, k_doc) => {
                                                    if(el_doc['visible'] !== undefined && el_doc['visible'] === true) {
                                                        return (
                                                            <div key={k_doc} className="mb-2">
                                                                <img className="img-fluid" src={el_doc.link} alt="" />
                                                                <a className="btn btn-primary" href={el_doc.link} target="_blank" rel="noreferrer">{el_doc.name}</a>
                                                            </div>
                                                        )
                                                    }
                                                    else {
                                                        return null
                                                    }
                                                })
                                                }
                                            </div>
                                        </div>
                                    :
                                        null
                                    }
                                </div>
                            </div>
                        </section>
                    )
                })
                }
            </main>
            <Footer />
        </>
    )
}