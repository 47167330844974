import React, { useState, useEffect, useRef } from 'react';
import './Slider.css';
import '../Pagina.css';

//import { language } from '../WEBContent/Language';

import { reqGET, set_language, content_language, repo_img_link/*, simpleAxiosGet */ } from '../../../Utils';

import * as Hosts from "../../../Hosts";

//import parse from 'html-react-parser';

import { Navigation, Pagination, Autoplay, Scrollbar, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

import "swiper/swiper-bundle.min.css";

import { language } from '../WEBContent/Language';

import Loader from '../../../loader';

//import AssecImage from "../ASSEC_Image/Image"

// import BaseName from "../BaseName";

// export default class Slider extends React.Component {
const Slider = () => {

    const [lang, set_lang] = useState('pt');
    const [lang_id, set_lang_id] = useState('1');

    const [, set_dados] = useState(null);
	const banner = useRef(null);
    // const [banner, setBanner] = useState([]);

    const [isloading, setLoading] = useState(true);

    /* 
        const change_lang = (l) => {
            set_language(l)
            set_lang(l)
    
            //console.log('language', localStorage.getItem('lang'))
            //console.log('localstorage', localStorage)
        } */


    // const getImageBg = async (linkImage, width, height, fit) => {
    //     linkImage = linkImage.replace("temp.assec.pt", "cdn.assec.app")
    //     return await simpleAxiosGet('https://cdn.assec.app', '/repo/image.php?img=' + linkImage + '&w=' + width + '&h=' + height + '&fit=' + fit)
    //     .then((res) => {
    //         return res
    //     })
    // }


    useEffect( () => {

        let getInfo = async () => {
            //setLoading(true)
            return await reqGET(`${Hosts.SIMCore_API}/web/content/type/${Hosts.companycode}/banner`)
                .then(async res => {
                    //setBanner(res.data)
                    //console.log('BANNER', res.data)
    
                    if (res.data.length > 0) {
    
                        var hoje = new Date();
                        let aux = []
                        res.data.forEach(async (v) => {
                            //let cache_img = ''
                            if(v.content[0].media.image !== undefined && v.content[0].media.image[0] !== undefined && v.content[0].media.image[0].link !== '') {
                                // v.content[0].media.image[0].link = 
                                // cache_img = await getImageBg(v.content[0].media.image[0].link, '1920', '1080', '')
                                // v.content[0].media.image[0].link = cache_img
                                //console.log(v.content[0].media.image[0].link)
    
                                if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                    let date_p = new Date(v.info.date.published);
                    
                                    if((hoje >= date_p)) {
                                        if(v.info.date.avaiable !== undefined && v.info.date.avaiable !== '') { 
                                            let date_a = new Date(v.info.date.avaiable);
                                            if(hoje <= date_a) {
                                                aux.push(v)
                                            }
                                        } else{
                                            aux.push(v)
                                        } 
                                    }
                                }
                            }
                            else {

                                if(v.info.date.published !== undefined && v.info.date.published !== '') {
                                    let date_p = new Date(v.info.date.published);
                    
                                    if((hoje >= date_p)) {
                                        if(v.info.date.avaiable !== undefined && v.info.date.avaiable !== '') { 
                                            let date_a = new Date(v.info.date.avaiable);
                                            if(hoje <= date_a) {
                                                aux.push(v)
                                            }
                                        } else{
                                            aux.push(v)
                                        } 
                                    }
                                }
                            }
                        })
    
                        // setBanner(aux)
                        //set_dados(banner.current = aux)
                        //console.log(banner.current)
                        return aux
                    }
    
                    //setLoading(false)
                    //calculo_margem()
                })
                .catch(erro => console.log('Erro', erro))
        }

        if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
            set_lang('pt')
            set_language('pt')
            set_lang_id('1')
        }
        else {
            set_lang(localStorage.getItem('lang'))
            content_language.forEach(el => {
                if (el.code === localStorage.getItem('lang')) {
                    set_lang_id(el.id)
                }
            })
        }


        getInfo()
            .then((res) => {
                // console.log(res)
                // setBanner(res)
                set_dados(banner.current = res)
                
                setTimeout(() => {
                    setLoading(false)
                  }, 750)

            })

        /*   if (localStorage.getItem('lang') === undefined || localStorage.getItem('lang') === null || localStorage.getItem('lang') === '') {
              change_lang('pt')
          }
          else {
              set_lang(localStorage.getItem('lang'))
          } */

        //console.log('language', localStorage.getItem('lang'))
        //console.log('localstorage', localStorage)


    }, [])

    //render() {

        return (isloading ?

            <Loader />
    
            :

                <section id="slider">
                    <div className="swiper swiper-container swiper-cartaz slideshow">
                    {
                    banner !== undefined && banner.current !== undefined && banner.current.length > 0 ?
                        <Swiper
                            modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y, EffectFade]}
                            spaceBetween={0}
                            slidesPerView={1}
                            //navigation={banner.length > 1 ? true : false}
                            navigation={{
                                nextEl: '.swiper-cartaz-button-next',
                                prevEl: '.swiper-cartaz-button-prev',
                            }}
                            loop={true}
                            speed={500}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: false
                            }}
                            effect="fade"
                            //pagination={banner.length > 1 ? true : false}
                            pagination={{
                                el: '.swiper-pagination-cartaz',
                                clickable: true
                            }}
                            breakpoints={{
                                768: {
                                    slidesPerView: 1,
                                    //navigation: true,
                                },
                                1200: {
                                    slidesPerView: 1,
                                    //navigation: true,
                                    //loop: true,
                                },
                            }}
                        /*scrollbar={{ draggable: true }}*/
                        //onSwiper={(swiper) => console.log(swiper)}
                        //onSlideChange={() => console.log('slide change')}
                        >
                            {
                            banner.current.length > 0 ?
                                banner.current.map((v, key) => {

                                    //console.log(v)

                                    //check if link has http (if not, add it to link)
                                    if(v.content[0].connection.link !== '' && v.content[0].connection.link.substr(0, 4) !== 'http') {
                                        v.content[0].connection.link = 'http://' + v.content[0].connection.link;
                                        //console.log(v.content[0].connection.link)

                                    }

                                    //if banner/image doesn't exist, add noimage_banner.jpg
                                    if(v.content[0].media.image[0] === undefined || v.content[0].media.image[0]['link'] === undefined || v.content[0].media.image[0]['link'] === '') {
                                        v.content[0].media.image[0] = {id: '', link: repo_img_link("nophoto_banner.jpg")}
                                    }
                                    else {
                                        // v.content[0].media.image[0] = getImageBg(v.content[0].media.image[0].link, '1280', '1024', 'fit');
                                    }

                                    return (
                                        
                                            (!v.info.button && (v.content[0].connection.link !== '' || v.content[0].connection.id !== '')) ?
                                                <div key={key}>
                                                    <SwiperSlide key={key} className={"slide-image slide-image-" + key} 
                                                        //style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + v.content[0].media.image[0].link + ")" }}
                                                        style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + 
                                                        
                                                        v.content[0].media.image[0].link 
                                                        
                                                        + ")" }}
                                                    >
                                                        {
                                                            v.content[0].connection.link !== '' ?

                                                                <a href={v.content[0].connection.link} target="_blank" rel="noreferrer" className="d-block h-100 w-100">
                                                            
                                                                    <span className={"slide-title caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                        <h1 className="cartaz-titulo">
                                                                            {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                                                        </h1>
                                                                        <h2 className="cartaz-subtitulo d-none d-md-block">
                                                                            {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                                        </h2>
                                                                    </span>
                                                                </a>
                                                            : 
                                                                <a href={Hosts.WEB_SITE_URI + (v.content[0].connection['type'] === 'partner' ? 'partner/' : 'pag/') + v.content[0].connection.id} /*target="_blank" rel="noreferrer"*/ className="d-block h-100 w-100">
                                                            
                                                                    <span className={"slide-title caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                                        <h1 className="cartaz-titulo">
                                                                            {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                                                        </h1>
                                                                        <h2 className="cartaz-subtitulo d-none d-md-block">
                                                                            {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                                        </h2>
                                                                    </span>
                                                                </a>
                                                        }
                                                        
                                                    </SwiperSlide>
                                                </div>
                                            :
                                                <div key={key}>
                                                    <SwiperSlide key={key} className={"slide-image slide-image-" + key} 
                                                        //style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + v.content[0].media.image[0].link + ")" }}>
                                                        style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + 
                                                        
                                                        v.content[0].media.image[0].link 
                                                        // getImageBg(v.content[0].media.image[0].link, '1280', '1024', 'fit', 'bg')
                                                        
                                                        + ")" }}>
                                                                
                                                        <span className={"slide-title caption_" + v.content[0].align.vertical + "_" + v.content[0].align.horizontal}>
                                                            <h1 className="cartaz-titulo">
                                                                {lang === 'pt' || v.content[0].language === undefined ? v.content[0].title : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].title === undefined || v.content[0].language[lang_id].title === '' ? v.content[0].title : v.content[0].language[lang_id].title)}
                                                            </h1>
                                                            <h2 className="cartaz-subtitulo d-none d-md-block">
                                                                {lang === 'pt' || v.content[0].language === undefined ? v.content[0].subtitle : (v.content[0].language[lang_id] === undefined || v.content[0].language[lang_id].subtitle === undefined || v.content[0].language[lang_id].subtitle === '' ? v.content[0].subtitle : v.content[0].language[lang_id].subtitle)}
                                                            </h2>
                                                            {
                                                                v.content[0].connection.link !== '' ?
                                                                    <a href={v.content[0].connection.link} target="_blank" rel="noreferrer" title={language[lang].btn_banner} alt={language[lang].btn_banner} className="btn btn-primary my-4">
                                                                        {language[lang].btn_banner}
                                                                    </a>
                                                                : null
                                                            }
                                                            {
                                                                v.content[0].connection.id !== '' ?
                                                                    <a href={Hosts.WEB_SITE_URI + (v.content[0].connection['type'] === 'partner' ? 'partner/' : 'pag/') + v.content[0].connection.id} /*target="_blank" rel="noreferrer"*/ title={language[lang].btn_banner} alt={language[lang].btn_banner} className="btn btn-primary mt-2 mt-md-4 mb-2">
                                                                        {language[lang].btn_banner}
                                                                    </a>
                                                                : null
                                                            }
                                                        </span>
                                                        
                                                    </SwiperSlide>
                                                </div>
                                    )
                                })
                            :
                                <SwiperSlide className={"slide-image slide-image"} style={{ backgroundImage: "linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 40%), url(" + repo_img_link("nophoto_banner.jpg") + ")" }}>
                                </SwiperSlide>
                            }

                            <div className="swiper-pagination-cartaz d-none d-md-block mb-3"></div>
                            <div className={"swiper-button-next swiper-cartaz-button-next " + (banner.current.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>
                            <div className={"swiper-button-prev swiper-cartaz-button-prev " + (banner.current.length > 1 ? 'd-none d-lg-block' : 'd-none')}></div>

                            <div className="col-12 d-none">
                                <section id="slider-btn" className="demo mt-5">
                                    <span></span>
                                </section>
                            </div>

                        </Swiper>
                    :
                        null
                    }
                    </div>
                </section>

    )
    //}

    //}
};
export default Slider;